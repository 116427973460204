import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";

const filteredQty = (qty) =>
  qty > 100 ? "100+ Available" : qty < 1 ? "Out Of Stock" : `${qty} Available`;

function createMarkup(html) {
  return { __html: html };
}

export default function CustomerProducts({ setProducts }) {
  const dispatch = useDispatch();
  let token = useSelector((state) => state.user.token);
  let sl = useSelector((state) => state.user.sl);

  let { custno } = useParams();

  async function getSpecialProducts(type, special = false) {
    try {
      const auth = { headers: { Authorization: `Bearer ${token}` } };
      const { data } = await axios.get(
        `${process.env.REACT_APP_API}/customers/${custno}/orders/items/${sl}`,
        auth
      );
      setProducts(data);
    } catch (error) {
      alert(error); // catches both errors
    }
  }

  useEffect(() => {
    if (custno) getSpecialProducts();
  }, [custno]);

  return <h4 className="ml-2 mt-2 text-gray-800">{custno}</h4>;
}
