import React, { useState } from "react";

export default function() {
  let [message, setMessage] = useState("");
  return (
    <div className="p-16 pl-32 pr-32">
      <form
        onSubmit={e => {
          e.preventDefault();
        }}
      >
        <label htmlFor="feedback" className="text-2xl text-gray-800">
          Please Leave Us Feedback
        </label>
        <textarea
          autoFocus
          id={"feedback"}
          className="border-black h-32"
          placeholder={"We will listen..."}
          onChange={e => setMessage(e.target.value)}
        />
        {message && (
          <div className="flex justify-end">
            <button className="mt-2" type={"submit"}>
              Submit
            </button>
          </div>
        )}
      </form>
    </div>
  );
}
