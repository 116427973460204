import _ from "lodash";
import React from "react";
import classNames from "classnames";
import {withRouter} from "react-router";

function Brands({ brands, setBrand, brand, history }) {

  return (
    <div
      id={"sidebarX"}
      className="absolute pt-2 pr-4 text-right"
      style={{ left: `-210px`, width: `210px` }}
    >
      <ul>
        <li className="text-xs lg:text-xl is-orange">Shop By Brand</li>
      </ul>
      <ul>
        {_.sortBy(brands,"name").map((s) => (
          <li
            className={classNames("text-lg cursor-pointer hover-orange", {
              "is-orange": s === brand,
            })}
            onClick={() => {
              setBrand(s);
              history.push("/");
            }}
          >
            {s}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default withRouter(Brands);
