import React, {useEffect, useRef, useState} from "react";
import Fade from "./animations/Fade";
import {addToOrder} from "../redux/actions/addToOrder";
import {useDispatch, useSelector} from "react-redux";
import classNames from "classnames";
import _ from "lodash";
import {useHistory} from "react-router-dom";

const filteredQty = (qty) =>
    qty > 100 ? "100+ Available" : qty < 1 ? "Out Of Stock" : `${qty} Available`;

export default function MiniProduct({product, brandName, selectedCategory}) {
    const history = useHistory();
    const dispatch = useDispatch();

    let cartProducts = useSelector((state) => state.cart.products);

    let [showAdd, setShowAdd] = useState(false);
    let [cartQty, setCartQty] = useState(
        cartProducts[product?.id] ? cartProducts[product?.id].qty : ""
    );
    let [isTouched, setIsTouched] = useState(false);
    let [isAdding, setIsAdding] = useState(false);

    useEffect(() => {
        if (isAdding) {
            setTimeout(() => {
                setIsAdding(false);
                setIsTouched(false);
            }, 575);
        }
    }, [isAdding]);

    const inputRef = useRef();

    if (!product) return null;

    let {
        id,
        itemno,
        model,
        name,
        main_image_hashname,
        logo,
        qty,
        price,
        brand,
        categories,
        sale_price,
    } = product;

    if (brandName && brand.name != brandName) return null;
    if (selectedCategory && !_.some(categories, {name: selectedCategory}))
        return null;

    const addProductToOrder = () => {
        dispatch(addToOrder(product, cartQty));
    };

    return (
        <Fade>
            <div
                className="product"
                onClick={() => {
                    setShowAdd(!showAdd);
                }}
            >
                <div className="flex justify-center">
                    <div style={{minHeight: 75, minWidth: 75}}>
                        <img
                            className="w-32"
                            src={process.env.REACT_APP_IMG_LIST + `/${main_image_hashname}`}
                            alt=""
                        />
                    </div>
                    <div className="flex flex-col justify-center items-center">
                        <div className="flex flex-col justify-center text-gray-900 text-center text-sm">
                            {`${model}`}
                        </div>
                        {sale_price > 0 && (
                            <>
                                <div className="text-gray-800 font-semibold text-xs line-through">
                                    ${price.toFixed(2)}
                                </div>
                                <div className="is-orange font-semibold text-xl">
                                    ${sale_price.toFixed(2)}
                                </div>
                            </>
                        )}
                        {!sale_price && (
                            <div className="is-orange font-semibold text-xl">
                                ${price.toFixed(2)}
                            </div>
                        )}
                        <div
                            className="text-gray-700 text-center text-xs"
                            style={{lineHeight: `15px`}}
                        >
                            {filteredQty(qty)}
                        </div>
                    </div>
                </div>

                {showAdd && (
                    <>
                        <Fade>
                            <div
                                className="flex mb-1 text-center text-gray-700 text-xs cursor-pointer"
                                onClick={() => history.push(`/product/${id}`)}
                            >
                                {name}
                            </div>
                            <div className="flex justify-around mt-1 align-middle">
                                <div
                                    className="product-row-item flex justify-center align-middle"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                    }}
                                >
                                    <input
                                        ref={inputRef}
                                        type="number"
                                        style={{width: `54px`}}
                                        value={cartQty}
                                        className={classNames("text-center", {
                                            "text-lg": cartQty && !isTouched,
                                            "is-loading": isAdding,
                                        })}
                                        style={{width: `54px`}}
                                        placeholder="Qty"
                                        onChange={(e) => {
                                            setCartQty(e.target.value);
                                            setIsTouched(true);
                                        }}
                                        onKeyDown={(e) => {
                                            if (e.key === "Enter" && cartQty) {
                                                addProductToOrder(product, cartQty, "");
                                                setIsAdding(true);
                                            }
                                        }}
                                    />
                                </div>
                                <button
                                    style={{width: `54px`}}
                                    className={classNames({
                                        "text-lg": cartQty && !isTouched,
                                        "is-loading": isAdding,
                                    })}
                                    onClick={(e) => {
                                        if (cartQty) {
                                            addProductToOrder();
                                            setIsAdding(true);
                                        } else {
                                            inputRef.current.focus();
                                        }
                                        e.preventDefault();
                                        e.stopPropagation();
                                    }}
                                >
                                    {(cartQty && !isTouched && "✓") || "ADD"}
                                </button>
                            </div>
                        </Fade>
                    </>
                )}
            </div>
        </Fade>
    )
        ;
}
