import axios from "axios";

async function getOrders(user, orders, status, setOrders, setLoading, take) {
  setLoading(true);
  try {
    const auth = { headers: { Authorization: `Bearer ${user.token}` } };
    axios
      .get(`${process.env.REACT_APP_API}/orders/${status}/${user.sl}?start=${take}`, auth)
      .then((data) => {
        if(!parseInt(take)){
          return setOrders(data.data);
        }
        let newOrders = orders.concat(data.data); 

        setLoading(false);
        return setOrders(newOrders);

      })
      .catch((e) => {
        console.error(e);
        setLoading(false);
        alert(e);
      });
  } catch (error) {
    alert(error); // catches both errors
  }
}


export default getOrders;
