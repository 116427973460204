import { animated, useSpring } from "react-spring";
import React from "react";

export default function Fade(props) {
    const spring = useSpring({
        config: { duration: 500 },
        from: { opacity: 0 },
        delay: 0,
        to: { opacity: 1 },
        leave: { opacity: 0 },
    });

    return <animated.div key={props.key} style={spring} {...props}>{props.children}</animated.div>;
}
