import React from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

function toTitleCase(str) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

const Customer = ({ customer, selectCustomer }) => {
  const cartCustomer = useSelector((s) => s.cart.customer);
  return (
    <>
      <div
        onClick={selectCustomer}
        className={classNames("w-5/12 select-none", {
          "is-orange": customer.Name === cartCustomer.Name,
          underline: customer.Name === cartCustomer.Name,
        })}
      >
        <div className="flex justify-start">
          <div style={{ minWidth: 300 }} className="flex flex-col">
            <span className="text-center is-orange text-center text-2xl">
              {toTitleCase(customer.Name.trim())}
              <span className="pb-4 text-black text-center text-xs">
                {" "}
                ({toTitleCase(customer.Custno.trim())}){" "}
              </span>
            </span>
            {customer.ADDR1.trim() && (
              <span className="text-center text-sm">
                {toTitleCase(customer.ADDR1)}, {toTitleCase(customer.CITY)}
              </span>
            )}
          </div>
        </div>
      </div>

      <Link cn={"w-3/12"} to={`/orders/custno/${customer.Custno}`}>
        <div className={"flex flex-col w-1/12 is-orange w-1/3 select-none"}>
          <span className="text-xs text-gray-700">View Orders</span>
        </div>
      </Link>
    </>
  );
};
export default Customer;
