import classNames from "classnames";
import Fade from "./animations/Fade";
import React, {useState} from "react";
import {useRecoilState} from "recoil";
import {formatMoney} from "../helpers";
import {CartProduct} from "./CartProduct";
import {orderAtom} from "../project/atoms";
import {Link, useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {submitOrder} from "../redux/actions/submitOrder";

export default function Cart() {
    const dispatch = useDispatch();
    const history = useHistory();

    const network = {
        online: true,
    };

    const cart = useSelector((state) => state.cart);
    console.log(cart)

    const [, setOrder] = useRecoilState(orderAtom);

    const [note, setNote] = useState(cart.note);
    const [submitted, setSubmitted] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [customer_po, setCustomerPo] = useState(cart.customer_po);

    const cartCustomer = useSelector((s) => s.cart.customer || null);

    let products = Object.values(cart.products);

    let total = Object.values(products)
        .map((s) => parseInt(s.qty) * parseFloat(s.price))
        .reduce((total, s) => total + s, 0);

    const handleSubmitOrder = (status) => () => {
        setSubmitted(true);
        setErrorMessage("")
        dispatch(
            submitOrder(
                {...cart, note, customer_po},
                cartCustomer,
                status,
                history,
                setOrder,
                setSubmitted,
                setErrorMessage
            )
        );
    }
    return (
        <>
            <div
                className={classNames(
                    "text-xl ml-12 mt-4 text-black text-2xl font-bold",
                    {
                        "text-red-600": !network.online,
                    }
                )}
            >
                My Cart {products.length > 0 && `( ${products.length} )`}
                {!network.online && <div className="text-lg text-red-600">OFFLINE</div>}
            </div>
            {products.length > 0 && (
                <Fade>
                    {products.map((s, index) => (
                        <CartProduct
                            key={s.id}
                            {...s}
                            cursor={true}
                            index={index}
                            handleRemoveItem={(id) => {
                                dispatch({
                                    type: "CART_REMOVE_PRODUCT",
                                    payload: id,
                                });
                            }}
                        />
                    ))}
                </Fade>
            )}
            {total && (
                <div className="flex justify-end">
                    <hr/>
                    <div className="text-xl mr-16 mt-4 text-black text-2xl font-bold">
                        Total {formatMoney(total)}
                    </div>
                </div>
            )}
            <div className="p-16 pl-32 pr-32 pb-6 font-semibold">
                <label htmlFor="feedback" className="text-lg text-gray-800">
                    {note ? "Note" : <br/>}
                </label>
                <textarea
                    id={"feedback"}
                    className={classNames("border-black h-32", {
                        "border-red-600 border-2 rounded": !network.online,
                    })}
                    placeholder={"Note : (for specific Model, include Model #)"}
                    value={note}
                    onChange={(e) => setNote(e.target.value)}
                />
            </div>
            {cartCustomer && cartCustomer.Name && (
                <>
                    <div className="italic text-gray-900 text-center">
                        Please review the information below placing your order
                    </div>

                    <div className="flex justify-center mt-8">
                        <div className="text-gray-900 font-bold">
                            <div className="text-xl">Shipping Information</div>

                            <div className="mt-2">
                                {cartCustomer.Name} ({cartCustomer.Custno})
                            </div>

                            <div className="">
                                {cartCustomer.ADDR1 && (
                                    <div className="">{`${cartCustomer.ADDR1}`}</div>
                                )}
                            </div>

                            {cartCustomer.ADDR2 && (
                                <div className="">{`${cartCustomer.ADDR2}`}</div>
                            )}

                            <div>
                                {cartCustomer.CITY && `${cartCustomer.CITY}`}
                                {cartCustomer.STATE && `, ${cartCustomer.STATE}`}
                                {cartCustomer.ZIP && ` ${cartCustomer.ZIP}`}
                            </div>
                        </div>
                        <div className="pl-2 pr-2 ml-8 mr-32">&nbsp;</div>
                        <div className="text-gray-900 font-bold">
                            <div className="text-xl text-gray-900 font-bold">Delivery</div>
                            <select className="mt-2 w-48 border">
                                <option value="volvo">Best Way</option>
                                <option value="saab">Pickup</option>
                            </select>
                            <input
                                className="block w-48 mt-2"
                                placeholder="Enter your PO Number"
                                value={customer_po}
                                onChange={(e) => setCustomerPo(e.target.value)}
                            />
                        </div>
                    </div>
                </>
            )}
            {(!cartCustomer || !cartCustomer.Name) && network.online && (
                <div className="italic text-gray-900 text-center">
                    <Link to={"customers"}>Click to Select a Customer</Link>
                </div>
            )}
            {errorMessage && (
                <div className="mt-8 mb-2 text-center">
                    <span className="bg-red-700 p-1 pl-6 pr-6 uppercase">{errorMessage}</span>
                </div>
            )}
            <div className="mt-8 mb-8 text-center">
                {cartCustomer && cartCustomer.Name && (
                    <button
                        disabled={submitted | !network.online}
                        className={classNames("mr-8 uppercase pl-16 pr-16 h-12 w-64", {
                            "is-loading": submitted,
                        })}
                        style={{backgroundColor: !network.online ? "red" : ""}}
                        onClick={handleSubmitOrder(1)}
                    >
                        {network.online ? "place order" : "offline"}
                    </button>
                )}
                <button
                    disabled={submitted | !network.online}
                    style={{backgroundColor: network.online ? "black" : "red"}}
                    className={classNames("uppercase pl-16 pr-16 h-12 w-64", {
                        "is-loading": submitted,
                    })}
                    onClick={handleSubmitOrder(0)}
                >
                    {network.online ? "save" : "offline"}
                </button>
            </div>
        </>
    );
}
