import React from "react";
import { formatMoney } from "../helpers";
import OrderDetailItem from "./OrderDetailItem";
import { DateTime } from "luxon";
import { fetchOrder } from "../project/fetches";
import { useParams } from "react-router";
import { useDispatch } from "react-redux";
import { addOrderToCart } from "./../redux/actions/addOrderToCart";
import { Link, useHistory } from "react-router-dom";

const OrderDetail = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [order, setOrder] = React.useState("");
  const [total, setTotal] = React.useState("");

  let { id } = useParams();

  window.scrollTo(0, 0);

  React.useEffect(() => {
    fetchOrder(id).then((s) => {
      setOrder(s);
      if (s && s.items)
        var total = s.items
          ? s.items
              .map((s) => parseInt(s.qty) * parseFloat(s.price))
              .reduce((total, s) => total + s, 0)
          : 0;
      setTotal(total);
    });
  }, []);

  const activateOrder = () => {
    // if (order.customer) {
    //   dispatch(addCartCustomer(order.customer));
    // }
    if (order.status) delete order.id;
    dispatch(addOrderToCart(order));
    localStorage.cart = JSON.stringify(order);
    history.push("/cart");
  };

  if (!order) return true;

  return (
    <div className="printable">
      <div className="p-8 text-gray-600">
        <h1 className="text-xl">{order.custno}</h1>
        {order.packno && (
          <h3 className="">
            <span className="text-black">AS400 :</span>
            {order.packno}
          </h3>
        )}
        {order.customer_po && (
          <h3 className="">Customer PO: {order.customer_po}</h3>
        )}
        <h4 className="text-sm">
          {DateTime.fromISO(order.updated_at).toLocaleString()}
        </h4>
        <h6 className="text-sm">{order.status ? "Submiited" : "Saved"}</h6>
        <p className="text-lg text-gray-700">{order.note}</p>
        <div className="text-xl text-gray-800">
          Total: {formatMoney(parseFloat(total))}
        </div>
        <div className="m-4 flex justify-between">
          <Link to={`/orders/custno/${order.custno}`}>
            <div
              className={"mr-8 flex flex-col items-end is-orange select-none "}
            >
              <span className="text-xs text-gray-700">View Orders</span>
              <div className="text-center">{order.custno}</div>
            </div>
          </Link>
          <Link to={`/customer-items/${order.custno}`}>
            <div
              className={"mr-8 flex flex-col items-end is-orange select-none "}
            >
              <span className="text-xs text-gray-700">View Active Items</span>
              <div className="text-center">{order.custno}</div>
            </div>
          </Link>
        </div>
      </div>
      {order.items &&
        order.items.map((s, index) => (
          <>
            {!((index + 1) % 7) && <div className="new-page" />}
            <OrderDetailItem item={s} />
          </>
        ))}

      <div className="m-4 flex justify-end">
        <button onClick={activateOrder}>Add Order to Cart</button>
      </div>
    </div>
  );
};

export default OrderDetail;
