const userData = localStorage.user ? JSON.parse(localStorage.user) : {};
const initialState = {
  ...{
    navigationType: localStorage.navigationType
      ? localStorage.navigationType
      : "menu",
    user: {},
    sl: null,
    isAuthenticated: false,
    authMessage: null,
    categories: localStorage.categories
      ? JSON.parse(localStorage.categories) || null
      : null,
  },
  // ...userData,
};

// , initialState);
export function user(state = initialState, action) {
  switch (action.type) {
    case "AUTH_RECEIVED": {
      if (!action.payload) {
        return {
          ...state,
          isAuthenticated: false,
          authMessage: "Please Try Again",
        };
      }

      let user = {
        ...state,
        ...{
          token: action.payload.token,
          user: action.payload.user,
          sl: action.payload.user.SL,
          isAuthenticated: true,
          authMessage: null,
        },
      };
      // localStorage.user = JSON.stringify(user);
      setTimeout((localStorage.user = JSON.stringify(user)));

      return user;
    }
    case "RESET_AUTH": {
      return {
        user: {},
        sl: null,
        isAuthenticated: false,
        authMessage: null,
      };
    }
    case "CUSTOMERS_RECEIVED": {
      return {
        ...state,
        customers: action.payload,
      };
    }
    case "USER_CUSTOMER": {
      return {
        ...state,
        user: {
          ...state.user,
          customer: action.payload,
        },
      };
    }

    case "FETCH_PRODUCT_AND_CATEGORIES_RECEIVED": {
      const { categories: categoriesTree, products } = action.payload;
      return {
        ...state,
        categoriesTree,
      };
    }
    case "FETCH_CATEGORY_NAMES": {
      return {
        ...state,
        categoriesByName: action.payload,
      };
    }

    case "USER_OBJECT": {
      return {
        ...state,
        ...action.payload,
      };
    }

    default:
      return state;
  }
}
