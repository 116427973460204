import axios from "axios";

export default function submitFeedback(feedback) {
  return (dispatch, getState) => {
    const { href } = window.location;
    let token = getState().user.token;
    let userId = getState().user.user.id;
    const auth = { headers: { Authorization: `Bearer ${token}` } };
    axios
      .post(
        `${process.env.REACT_APP_API}/feedback`,
        {
          userId,
          feedback,
          href,
        },
        auth
      )
      .then(({ data }) => {
        console.log(data);
      })
      .catch((e) => {
        console.error(e);
      });
  };
}
