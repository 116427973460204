import React from "react";
import { useRecoilState } from "recoil";
import { customersAtom } from "../project/atoms";
import { useDispatch, useSelector } from "react-redux";
import Customer from "./Customer";
import getCustomers from "./project/getCustomers";
import _ from "lodash";
import { useHistory } from "react-router";
import axios from "axios";
import CustomerTotals from "./CustomerTotals";
import { addCartCustomer } from "../redux/action-creators/addCartCustomer";
import { scrollToTop } from "./project/behaviours";

const step = 15;

export default function Customers() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const [start, setStart] = React.useState(0);
  const [customers, setCustomers] = useRecoilState(customersAtom);
  const [loading, setLoading] = React.useState(false);
  const [search, setSearch] = React.useState("");
  const [customerSearch, setCustomerSearch] = React.useState(false);
  const [searchCustomers, setSearchCustomers] = React.useState(false);
  const history = useHistory();
  React.useEffect(() => {
    scrollToTop();
    if (!_.isEmpty(customers)) {
      return;
    }
    getCustomers(user, setCustomers, setLoading);
  }, []);

  const handleSearch = async () => {
    const auth = { headers: { Authorization: `Bearer ${user.token}` } };
    const customers = await axios
      .get(`${process.env.REACT_APP_API}/customers/${user.sl}/${search}`, auth)
      .then((data) => {
        return data.data;
      })
      .catch((e) => {
        console.error(e);
      });
    setSearchCustomers(customers);
    setCustomerSearch(true);
  };

  const selectCustomer = (s) => () => {
    history.push("/cart");
    dispatch(addCartCustomer(s));
  };

  return (
    <>
      <div className="">
        <div className="p-8 flex ">
          <input
            type="text"
            onKeyPress={(e) =>
              e.key === "Enter" && search.length > 2 && handleSearch()
            }
            onChange={(e) => {
              setSearch(e.target.value);
              if (e.target.value.length < 3) {
                setCustomerSearch(false);
              }
            }}
            placeholder="search"
          />
        </div>
      </div>

      {/*<Spinner loading={loading} />*/}
      {customerSearch &&
        searchCustomers.map((s) => (
          <div
            className="flex w-full p-4 items-center text-gray-800 text-lg"
            key={s.Custno}
          >
            <Customer customer={s} selectCustomer={selectCustomer(s)} />
          </div>
        ))}
      {!customerSearch &&
        customers.map((s) => (
          <div
            className="flex w-full p-4 items-center text-gray-800 text-lg"
            key={s.Custno}
          >
            <CustomerTotals
              customer={s}
              selectCustomer={selectCustomer(s.customer)}
            />
          </div>
        ))}
    </>
  );
}
