import _ from "lodash";
import classNames from "classnames";
import Fade from "./animations/Fade";
import {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {noImageBrands} from "../project/brands";
import {useDispatch, useSelector} from "react-redux";
import {addToOrder} from "../redux/actions/addToOrder";

const filteredQty = (qty) =>
    qty > 100 ? "100+ Available" : qty < 1 ? "Out Of Stock" : `${qty} Available`;

export default function Product({product, brandName, selectedCategory}) {
    const history = useHistory();
    const dispatch = useDispatch();

    let cartProducts = useSelector((state) => state.cart.products);

    let [cartQty, setCartQty] = useState(
        product?.id && cartProducts[product?.id] ? cartProducts[product?.id].qty : ""
    );
    let [isTouched, setIsTouched] = useState(false);
    let [isAdding, setIsAdding] = useState(false);

    useEffect(() => {
        if (isAdding) {
            setTimeout(() => {
                setIsAdding(false);
                setIsTouched(false);
            }, 575);
        }
    }, [isAdding]);

    if (!product) return null;

    let {
        id,
        itemno,
        model,
        name,
        main_image_hashname,
        qty,
        price,
        brand,
        categories,
        sale_price,
    } = product;

    if (brandName && brand.name != brandName) return null;
    if (selectedCategory && !_.some(categories, {name: selectedCategory}))
        return null;


    const addProductToOrder = () => {
        dispatch(addToOrder(product, cartQty));
    };
    return (
        <Fade>
            <div
                className="product"
                onClick={() => {
                    history.push(`product/${id}`);
                }}
            >
                <div className="flex justify-center">
                    <div style={{minHeight: 234, minWidth: 234}}>
                        <img
                            src={process.env.REACT_APP_IMG_LIST + `/${main_image_hashname}`}
                            alt=""
                        />
                    </div>
                </div>
                <h5
                    className="tracking-wide text-gray-900 text-center text"
                    style={{lineHeight: `20px`}}
                >
                    {`${name}`}
                </h5>

                <div className="p-4">
                    <div
                        className="text-gray-700 text-center text-lg"
                        style={{lineHeight: `15px`}}
                    >
                        {filteredQty(qty)}
                    </div>
                    <div className="text-center text-gray-900 text-">{itemno}</div>
                </div>

                <div className="flex justify-center">
                    <div className="flex flex-col justify-center">
                        {!noImageBrands.includes(brand.name) ? (
                            <img
                                alt={brand.name}
                                style={{
                                    width: 66,
                                    maxWidth: "none",
                                }}
                                src={process.env.REACT_APP_IMG_BRAND + `/${brand.image}`}
                            />
                        ) : (
                            <h1>{brand.name}!!</h1>
                        )}
                    </div>
                    <div style={{width: `15px`}}>&nbsp;</div>
                    <div className="flex flex-col justify-center text-gray-900 text-sm">{`${model}`}</div>
                </div>

                <div className="flex justify-around mt-4 align-middle">
                    <div className="flex items-center justify-center">
                        {sale_price && (
                            <div className="flex flex-col justify-center">
                                <div className="product-row-item flex flex-col justify-center content-center">
                                    <div
                                        className="text-gray-800 font-semibold text-sm line-through">${price.toFixed(2)}</div>
                                </div>
                                <div className="product-row-item flex flex-col-justify-center">
                                    <div className="is-orange font-semibold text-2xl">${sale_price.toFixed(2)}</div>
                                </div>
                            </div>
                        )}
                        {!sale_price && (
                            <div className="product-row-item flex flex-col-justify-center">
                                <div className="is-orange font-semibold text-2xl">${price.toFixed(2)}</div>
                            </div>
                        )}
                        <div
                            className="product-row-item flex justify-center align-middle"
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                            }}
                        >
                            <input
                                style={{width: `54px`}}
                                value={cartQty}
                                className={classNames("text-center", {
                                    "text-lg": cartQty && !isTouched,
                                    "is-loading": isAdding,
                                })}
                                style={{width: `54px`}}
                                placeholder="Qty"
                                onChange={(e) => {
                                    setCartQty(e.target.value);
                                    setIsTouched(true);
                                }}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter" && cartQty) {
                                        addProductToOrder(product, cartQty, "");
                                        setIsAdding(true);
                                    }
                                }}
                            />
                        </div>
                        <button
                            style={{width: `64px`}}
                            className={classNames("product-detail-add", {
                                "text-lg": cartQty && !isTouched,
                                "is-loading": isAdding,
                            })}
                            onClick={(e) => {
                                if (cartQty) {
                                    addProductToOrder();
                                    setIsAdding(true);
                                }
                                e.preventDefault();
                                e.stopPropagation();
                            }}
                        >
                            {(cartQty && !isTouched && "✓") || "ADD"}
                        </button>
                    </div>
                </div>
            </div>
        </Fade>
    );
}
