import {Link} from "react-router-dom";
import {useSelector} from "react-redux";

export function NavCart() {
    const count = useSelector(
        (state) => Object.values(state.cart.products).length
    );

    return (
        <div className="flex justify-end items-center">
            <Link to={"/cart"} id="cart">
                <img
                    className="select-none"
                    src="https://s3.amazonaws.com/magento-luzerne/zreiss/shopping+cart.png"
                    alt=""
                />
                {count > 0 && (
                    <div id="cart-qty" className="absolute select-none">
                        {count}
                    </div>
                )}
            </Link>
        </div>
    );
}
