import React, { useState } from "react";
import { useParams } from "react-router";
import Orders from "./Orders";
import getOrdersCustno from "./project/getOrdersCustno";

export default function OrdersCustno() {
  const { custno } = useParams();
  const [orders, setOrders] = useState([]);
  React.useEffect(() => {
    getOrdersCustno(custno).then((s) => setOrders(s));
    // async function fetchData() {
    //   const response = await getOrdersCustno(custno);
    // }
    // fetchData();
  }, []);
  if (!orders) return null;
  return <Orders orders={orders} customer={custno} />;
}
