import {getLowestPriceWithType, getProductPrice} from "../../project/product";

export function addToOrder(product, qty, note) {
    return (dispatch, getState) => {
        const products = getState().products.products;
        const cart = getState().cart;

        let info = {};
        const {price, priceType, priceMessage, regularPrice} = getLowestPriceWithType(product,qty,cart);
        const {sale_price, clearance_price, status, sale_min_order_total, sale_min_quantity} = product;


        if (!products[product.id]) {
            info = product;
        }
        dispatch({
            type: "CART_PRODUCTS",
            payload: {
                id: product.id,
                object: {
                    id: product.id,
                    itemno: product.itemno,
                    qty,
                    note,
                    info,
                    price,
                    status,
                    priceType,
                    sale_price,
                    regularPrice,
                    priceMessage,
                    clearance_price,
                    sale_min_quantity,
                    sale_min_order_total,
                },
            },
        });
    };
}
