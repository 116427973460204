import React from "react";

import Grow from "./animations/Grow";
import { animated, useSpring } from "react-spring";

import { useDispatch, useSelector } from "react-redux";
import { showToggle } from "../redux/action-creators/show";

import { useRecoilState } from "recoil";
import { subMenuTypeAtom, toggleSubMenuType } from "../project/atoms";

const Customer = ({ customer, select }) => {
  const spring = useSpring({
    from: { transform: "scale(0.5)" },
    to: { transform: "scale(1)" },
    config: { duration: 400 },
  });

  if (!customer) return null;

  return (
    <animated.div
      style={spring}
      className="text-white text-xs text-left font-bold hover:text-orange-700 cursor-pointer select-none"
      onClick={select}
    >
      {customer.Name}
    </animated.div>
  );
};

export default function User() {
  const [subMenuType, setSubMenuType] = useRecoilState(subMenuTypeAtom);
  const cartCustomer = useSelector((s) => s.cart.customer);
  const user = useSelector((state) => state.user.user);
  const dispatch = useDispatch();

  if (!user) {
    return null;
  }

  return (
    <Grow>
      <div
        className="flex flex-col items-baseline mt-2 mr-1"
        onClick={() => toggleSubMenuType(subMenuType, setSubMenuType)}
      >
        <Customer
          customer={cartCustomer}
          select={() => dispatch(showToggle("showMyAccountNav"))}
        />
        <div
          className="is-orange font-bold hover:text-orange-700 cursor-pointer select-none"
          onClick={() => toggleSubMenuType(subMenuType, setSubMenuType)}
          // onClick={() => setSubMenuType("menu")}
        >
          {user.email}
        </div>
      </div>
    </Grow>
  );
  // return <pre>{JSON.stringify(user, null, 2)}</pre>;
}
