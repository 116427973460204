import _ from "lodash";
import React from "react";
import {useRecoilState} from "recoil";
import SubCategory from "./SubCategory";
import algoliasearch from "algoliasearch";
import {useDispatch, useSelector} from "react-redux";
import {preventPropogation} from "../helpers/helpers";
import {setSubcategories} from "../redux/actions/setSubcategories";
import {categorySlugAtom, subCategoriesAtom} from "../project/atoms";
import {clearZreissProducts} from "../redux/action-creators/products";

const client = algoliasearch("9M92AFELTS", "9fc580b99a078a341ab22d0796a53ebc");
const index = client.initIndex("products");
index.setSettings({
  hitsPerPage: 200,
});

const SubCategoriesBar = ({
  isAuthenticated,
  searching,
  // categories,
  category,
  setProducts,
  setCategory,
  setBrands,
  clearCategoryFilter,
  handleClearBrand,
  handleSetSearching,
}) => {
  const [subCategories, addSubCategories] = useRecoilState(subCategoriesAtom);
  let categories = useSelector((state) => state.products.categoriesTree);
  let dispatch = useDispatch();
  const [, setCategorySlugAtom] = useRecoilState(categorySlugAtom);
  const searchCategory = (e, category) => {
    preventPropogation(e);

    dispatch(setSubcategories(category, addSubCategories));
    clearCategoryFilter();
    handleSetSearching(false);
    handleClearBrand();
    handleSetSearching(false);

    setProducts(categories[category.slug].products);
    setBrands(categories[category.slug].brands);
    setCategorySlugAtom(category.slug);
    dispatch(clearZreissProducts());
  };

  if (_.isEmpty(subCategories)) return null;
  if (searching) return null;
  const sortedCategories = _.sortBy(subCategories, "name");
  return (
    <>
      {_.chunk(
          sortedCategories,
        subCategories.length > 10
          ? subCategories.length > 13
            ? 6
            : 4
          : subCategories.length > 2
          ? 2
          : 1
      ).map((a, index) => (
        <div className={"flex flex-col items-center"} key={index}>
          <div className={"other-category text-black"}>
            {a.map((s) => (
              <SubCategory key={s.slug} searchCategory={searchCategory} category={s} />
            ))}
          </div>
        </div>
      ))}
    </>
  );
};

export default SubCategoriesBar;
