import React from "react";
import classNames from "classnames";

const ProductImage = ({ selected, image, handleSelected }) => (
    <div
        className={classNames("w-1/4 mb-1 flex justify-center items-center", {
            border: selected,
            "shadow-md": selected
        })}
        onClick={handleSelected}
    >
        <img
            alt={image.label}
            style={{ height: `82px` }}
            className="p-2"
            src={
                process.env.REACT_APP_IMG_PRODUCT + "/" +
                image.filename
            }
        />
    </div>
);

export default ProductImage;