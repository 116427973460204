import classNames from "classnames";
import {noImageBrands} from "../project/brands";
import React, {useEffect, useState} from "react";

export const CatalogCategoryProductBrands = ({containerWidth, productBrands, handleSelectBrand, brandName}) => {
    const brandsLength = 110;
    const [brandsPage, setBrandsPage] = useState(0);
    const [currentBrand, setCurrentBrand] = useState(brandName);
    const itemsPerScroll = Math.floor(containerWidth / brandsLength);


    useEffect(() => {
        if (!brandName && !currentBrand) setBrandsPage(0);
        setCurrentBrand(brandName);
    }, [productBrands, brandName]);

    useEffect(() => {
        if (![...productBrands].splice((brandsPage * itemsPerScroll), itemsPerScroll).length) {
            return setBrandsPage(0);
        }
    }, [brandsPage]);

    const handleScrollBrands = () => {
        if ((itemsPerScroll * (brandsPage + 1)) > productBrands.length) {
            return setBrandsPage(0);
        }

        setBrandsPage(brandsPage + 1)
    }


    if (!productBrands) return null;

    const splicedBrands = [...productBrands].splice((brandsPage * itemsPerScroll), itemsPerScroll);

    return (
        <>
            {splicedBrands.filter(s=>s.name).map((s) => (
                <div
                    key={s.id}
                    onClick={handleSelectBrand(s.name)}
                    className={classNames("cursor-pointer ml-4 mr-4 pt-4 pb-2", {
                        "border-b-2 border-orange-600": s.name === brandName,
                    })}
                >
                    {!noImageBrands.includes(s.name) ? (
                        <img
                            alt={s.name}
                            style={{
                                width: 66,
                                maxWidth: "none",
                            }}
                            src={process.env.REACT_APP_IMG_BRAND + `/${s.image}`}
                        />) : (
                        <h1>{s.name}</h1>
                    )}
                </div>
            ))}
            {productBrands.length > splicedBrands.length && (
                <h1 onClick={handleScrollBrands}
                    className={"flex-1 text-right text-gray-700 text-xl pt-2 pr-5 cursor-default select-none"}
                >
                    >>
                </h1>
            )}
        </>
    );
};
