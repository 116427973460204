import classNames from "classnames";
import { Link } from "react-router-dom";
import { useRecoilState } from "recoil";
import React, { useState } from "react";
import algoliasearch from "algoliasearch";
import { useDispatch, useSelector } from "react-redux";

import SecondNav from "./SecondNav";
import { scrollToTop } from "./project/behaviours";
import { clearBrand } from "../redux/action-creators";
import { setSubcategories } from "../redux/actions/setSubcategories";
import { clearZreissProducts } from "../redux/action-creators/products";

import {
  categoryAtom,
  categorySlugAtom,
  subCategoriesAtom,
  subMenuTypeAtom,
} from "../project/atoms";

const client = algoliasearch("9M92AFELTS", "9fc580b99a078a341ab22d0796a53ebc");
const index = client.initIndex("products");
index.setSettings({
  hitsPerPage: 200,
});
window.algoliaIndex = index;

export default function Categories({
  isAuthenticated,
  searching,
  // categories,
  algoliaHits,
  category,
  setProducts,
  setCategory,
  setCategories,
  setBrands,
  clearCategoryFilter,
  handleClearBrand,
  handleSetSearching,
}) {
  let dispatch = useDispatch();

  let categories = useSelector((state) => state.products.categoriesTree);

  const [categorySearch, setCategorySearch] = useState("electronics");

  const [, setCategoryAtom] = useRecoilState(categoryAtom);
  const [, setSubMenuType] = useRecoilState(subMenuTypeAtom);
  const [, addSubCategories] = useRecoilState(subCategoriesAtom);
  const [, setCategorySlugAtom] = useRecoilState(categorySlugAtom);

  const searchCategory = (category) => {
    setSubMenuType("subCategories");
    setCategoryAtom(category.name);
    setCategorySlugAtom(category.slug);

    setProducts(categories[category.slug].products);
    setBrands(categories[category.slug].brands);

    dispatch(setSubcategories(category, addSubCategories));
    dispatch(clearZreissProducts());

    setCategorySearch(category.name);
    clearCategoryFilter();
    handleSetSearching(false);
    handleClearBrand();
    handleSetSearching(false);
  };

  if (!categories) return null;
  return (
    <div
      id="parent-categories"
      style={{ backgroundColor: `rgb(94, 94, 94)` }}
      className={classNames(
        "z-50 text-xs lg:mt-4 lg:text-base lg:block lg:w-full no-print",
        {
          "is-orange": true,
          // fixed: fixed,
          // "lg:mt-4": !fixed,
          // "pt-2": fixed,
        }
      )}
    >
      <div className="m-auto flex justify-between" style={{ maxWidth: 1070 }}>
        {categories["default"].children.map((s, index) => (
          <Link
            onClick={() => {
              scrollToTop();
              searchCategory(s);
              dispatch(clearBrand());
            }}
            to={`/${s.slug}`}
            key={s.slug}
            style={{ borderColor: `#eb6433` }}
            className={classNames(
              "nav-item active electronics text-white hover-orange",
              {
                "is-orange orange-border border-b-2": s.name === categorySearch,
              }
            )}
          >
            {s.name}
          </Link>
        ))}
      </div>
      {/*{navigationType === "menu" && (*/}
      <>
        <SecondNav
          setBrands={setBrands}
          searching={searching}
          categories={categories}
          setCategory={setCategory}
          algoliaHits={algoliaHits}
          isAuthenticated={isAuthenticated}
          handleClearBrand={handleClearBrand}
          handleSetSearching={handleSetSearching}
          setProducts={(hits) => setProducts(hits)}
          clearCategoryFilter={clearCategoryFilter}
          // category={categoryFilter}
        />
      </>
      {/*)}*/}
    </div>
  );
}
