export function addCustomer(object) {
  return {
    type: "CART_ADD_CUSTOMER",
    payload: object,
  };
}

export function addUser(object) {
  return {
    type: "USER_OBJECT",
    payload: object,
  };
}
