import React from "react";

const OrderDetailItem = ({ item }) => {
  // return <pre>{JSON.stringify(item,null,2)}</pre>
  if (!item.product)
    return (
      <div className="flex w-full justify-around items-center is-orange text-center text-2xl">
        <h1>Something Wrong</h1>
      </div>
    );
  const { qty, price, note, product } = item;
  const { itemno, main_image_hashname: image, upc } = product;
  return (
    <div className="flex w-full justify-around items-center is-orange text-center text-2xl">
      <img
        className="w-32"
        src={process.env.REACT_APP_IMG_LIST + `/${image}`}
        alt={itemno}
      />
      <div className="w-1/5">
        <h3 className="text-sm text-gray-600">{upc}</h3>
        <h1>{itemno}</h1>
      </div>
      <div className="w-1/5">
        <h3 className="text-sm text-gray-600">qty</h3>
        <div>{qty}</div>
      </div>
      <div className="w-1/5">
        <h3 className="text-sm text-gray-600">price</h3>
        <div>
          {price}
          {note && <span className="text-xs text-gray-700 ml-2">({note})</span>}
        </div>
      </div>
      <div className="w-1/5">
        <h3 className="text-sm text-gray-600">total</h3>
        <div>${(price * qty).toFixed(2)}</div>
      </div>

      {/* <div className="w-1/5">{note}</div> */}
      {/*<pre>{JSON.stringify(itemno, null, 2)}</pre>*/}
    </div>
  );
};

export default OrderDetailItem;
