import clearCart from "./clearCart";

export function submitOrderData() {
  return (dispatch, getState) => {
    const { user } = getState();

    let token = getState().user.token;
    const userId = user.user.id;
    const feedback = localStorage.cart;
    dispatch({ type: "SUBMIT_ORDER_START" });
    fetch(`${process.env.REACT_APP_API}/feedback`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ feedback, userId }),
    })
      .then((resp) => resp.json())
      .then((data) => {})
      .catch((error) => {
        console.log(error);
      });
  };
}
