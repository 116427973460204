import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import Order from "./Order";
import { scrollToTop } from "./project/behaviours";

export default function Orders({ orders, customer = "" }) {
  const user = useSelector((state) => state.user);
  useEffect(() => scrollToTop(), []);
  if (!orders) return null;
  if (!user.isAuthenticated) return null;
  return (
    <>
      <h1 className="text-2xl ml-8 mt-4 text-gray-700">{customer}</h1>
      <div className="">
        {orders.map((s, index) => (
          <Order key={s.id} order={s} index={index} customer={customer} />
        ))}
      </div>
    </>
  );
}
