import axios from "axios";
import { useRecoilState } from "recoil";
import { useEffect, useState } from "react";
import { categoryAtom } from "../project/atoms";
import { addCurrent } from "../redux/action-creators";
import { useDispatch, useSelector } from "react-redux";
import { clearZreissProducts } from "../redux/action-creators/products";

export const useProductsView = ({
  setProducts,
  setCategory,
  handleClearBrand,
  handleSetSearching,
}) => {
  const dispatch = useDispatch();

  let token = useSelector((state) => state.user.token);

  const [, setCategoryAtom] = useRecoilState(categoryAtom);
  const [categorySearch, setCategorySearch] = useState("back-in-stock");

  async function getSpecialProducts(type, special = false) {
    setCategoryAtom("");
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API}/products-${type}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setProducts(data);
      setCategory("");

      dispatch(
        addCurrent({
          category: "",
        })
      );
      dispatch(clearZreissProducts());
      handleClearBrand();
      handleSetSearching(true);
    } catch (error) {
      alert(error); // catches both errors
    }
  }

  const searchSpecials = async (type) => {
    getSpecialProducts(type);
  };

  useEffect(() => {
    searchSpecials(categorySearch);
  }, [categorySearch]);

  return {
    searchSpecials,
    setCategorySearch,
    categorySearch,
  };
};
