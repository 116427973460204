import thunk from 'redux-thunk';
import rootReducer from '../reducers';
import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
// import { loadState, saveState } from './../../helpers/localStorage';
// import firebaseMiddleware from "../../database/firebaseMiddleware"

// const persistedState = loadState();

const composeEnhancers = composeWithDevTools({ trace: true, traceLimit: 25 });

let middleware = composeEnhancers(applyMiddleware(thunk));

// export default createStore(rootReducer, {}, middleware);

export default function configureStore(persistedState) {
	return createStore(rootReducer, {}, middleware);
}


// export default function configureStore(persistedState) {
// 	return createStore(rootReducer, {}, composeWithDevTools(applyMiddleware(thunk)));
// }
