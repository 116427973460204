import Orders from "./Orders";
import {useSelector} from "react-redux";
import getorders from "./project/getOrders";
import {scrollDown} from "../project/functions";
import React, {useEffect, useState} from "react";

export default function OrdersStatus() {
  const user = useSelector((state) => state.user);
  // const [loading, setLoading] = useState(false);
  const [take, setTake] = useState(0);
  const [orders, setOrders] = useState([]);
  const [status, setStatus] = useState(1);

  const setLoading = () => null;

  useEffect(() => {
    if (!user.isAuthenticated) return;

    getorders(user, orders, status, setOrders, setLoading, take);
    take && setTimeout(scrollDown, 600);
  }, [take, status]);

  const changeStatus = (status) => () => {
    setTake(0);
    setStatus(status);
  };

  return (
    <>
      <div className="">
        <h2
          className="text-3xl text-gray-600 p-4"
          onClick={changeStatus(status ? 0 : 1)}
        >
          {status ? "Submitted" : "Saved"}
          <span className="is-orange text-xs ml-2 cursor-pointer hover:underline">
            View {status ? "Saved" : "Submitted"}
          </span>
        </h2>
        <div className="">
          <Orders orders={orders} />
        </div>
        {/*<Spinner loading={loading} />*/}
      </div>
      <div className="flex justify-end mr-8">
        {status && <button onClick={() => setTake(take + 10)}>Next 10</button>}
      </div>
    </>
  );
}
