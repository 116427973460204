export const noImageBrands = [
    "T-Rex Digital",
    "Sound Aura",
    "Digital Gadgets",
    "T-Rex Digital",
    "Sylvan Ridge",
    "Royal",
    "Quick Connect Products",
    "Cobra",
    "Asrock",
    "Digital etc",
    "Gourmet Chef",
    "Gourmet Edge",
    "Dream Zone",
    "Chief"
];