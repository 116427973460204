import React from "react";
import {Link} from "react-router-dom";
import {useRecoilState} from "recoil";
import algoliasearch from "algoliasearch";
import {setUniqueBrands} from "../helpers";
import {subCategoriesAtom} from "../project/atoms";
import {useDispatch, useSelector} from "react-redux";
import {setSubcategories} from "../redux/actions/setSubcategories";

const client = algoliasearch("9M92AFELTS", "9fc580b99a078a341ab22d0796a53ebc");
const index = client.initIndex("products");
index.setSettings({
    hitsPerPage: 200,
});
window.algoliaIndex = index;

const CategoryBreadcrumb = ({
                                clearCategoryFilter,
                                handleSetSearching,
                                handleClearBrand,
                                setBrands,
                                setProducts,
                            }) => {
    let dispatch = useDispatch();

    const [, addSubCategories] = useRecoilState(subCategoriesAtom);
    const category = useSelector((state) => state.current.category);

    if (!category) return null;
    if (category.parents[0].parent_category_id === 1) return <Link
        to={category.slug}
        className="ml-4 text-gray-800 text-sm"
    >
        {category.name}
    </Link>;

    const searchCategory = (category) => {
        handleClearBrand();
        clearCategoryFilter();
        handleSetSearching(false);
        handleSetSearching(false);

        dispatch(setSubcategories(category, addSubCategories));

        const query = category.name;
        index.search(
            {
                query: query,
                restrictSearchableAttributes: ["categories"],
            },
            (err, {hits} = {}) => {
                if (err) throw err;

                setProducts(hits);
                setUniqueBrands(hits, setBrands);
            }
        );
    };

    return (
        <div className="p-2">
            {[...category?.parents].filter(s => s.id !== 2).reverse().map((s) => (
                <>
                    <Link
                        to={s.slug}
                        className="text-gray-800 text-sm"
                        onClick={() => searchCategory(s)}
                    >
                        {s.name}
                    </Link>
                    <span className="pl-1 pr-1 text-gray-900 text-xs">>></span>
                </>
            ))}
            <span className="text-gray-800 text-sm">{category.name}</span>
        </div>
    );
};

export default CategoryBreadcrumb;
