import {useSelector} from "react-redux";
import {Route, Switch} from "react-router";
import {BrowserRouter} from "react-router-dom";
import React, {useEffect, useState} from "react";

import Nav from "./components/Nav";
import Cart from "./components/Cart";
import Login from "./components/Login";
import Footer from "./components/Footer";
import Logout from "./components/Logout";
import Brands from "./components/Brands";
import Catalog from "./components/Catalog";
import Settings from "./components/Settings";
import Feedback from "./components/Feedback";
import Products from "./components/Products";
import Customers from "./components/Customers";
import Categories from "./components/Categories";
import OrderDetail from "./components/OrderDetail";
import OrderStatus from "./components/OrdersStatus";
import OrdersCustno from "./components/OrdersCustno";
import ProductDetail from "./components/ProductDetail";
import {ProductsView} from "./components/ProductsView";
import CustomerProducts from "./components/CustomerProducts";
import PrivateRoute from "./components/project/PrivateRoute";

function App() {
    const isAuthenticated = useSelector((state) => state.user.isAuthenticated);

    const [brand, setBrand] = useState("");
    const [brands, setBrands] = useState([]);
    const [products, setProducts] = useState([]);
    const [categories, setCategories] = useState("");
    const [isSearching, setSearching] = useState(false);
    const [categoryFilter, setCategoryFilter] = useState("");

    const network = {
        online: true,
    };

    const clearBrand = () => setBrand("");
    const clearCategoryFilter = () => setCategoryFilter("");

    useEffect(() => {
        const startProducts = JSON.parse(localStorage.getItem("products"));
        if(startProducts === null) return;

        const startProductsArray = Object.values(startProducts);

        const maxStart = startProductsArray.length - 100;
        const start = Math.floor(Math.random() * (maxStart + 1));
        setProducts(startProductsArray.slice(start, start + 100) || []);
    }, []);
    return (
        <BrowserRouter>
            <div id="grid">
                <div
                    id="top-orange"
                    className="mb-3 text-center"
                    style={{backgroundColor: !network.online ? "red" : ""}}
                >
                    {network.online ? (
                        ""
                    ) : (
                        <div className="bg-red-600 text-white font-bold">OFFLINE</div>
                    )}
                </div>
                <Nav
                    setBrands={setBrands}
                    setCategories={setCategories}
                    handleClearBrand={clearBrand}
                    handleSetSearching={setSearching}
                    isAuthenticated={isAuthenticated}
                    setProducts={(hits) => setProducts(hits)}
                />
                <div id="nav-shadow" className="h-2">
                    &nbsp;
                </div>
                <Categories
                    setBrands={setBrands}
                    algoliaHits={products}
                    searching={isSearching}
                    categories={categories}
                    setCategories={setCategories}
                    handleClearBrand={clearBrand}
                    setCategory={setCategoryFilter}
                    isAuthenticated={isAuthenticated}
                    handleSetSearching={setSearching}
                    setProducts={(hits) => setProducts(hits)}
                    clearCategoryFilter={clearCategoryFilter}
                    // category={categoryFilter}
                />
                <div id="content" className="rounded">
                    <Switch>
                        <Route path="/login">
                            <Login/>
                        </Route>
                        <Route path="/logout">
                            <Logout/>
                        </Route>

                        <PrivateRoute exact path="/products">
                            <ProductsView
                                handleClearBrand={clearBrand}
                                setCategory={setCategoryFilter}
                                handleSetSearching={setSearching}
                                setProducts={(hits) => setProducts(hits)}
                            >
                                <Products
                                    brand={brand}
                                    products={products}
                                    setBrand={setBrand}
                                    setBrands={setBrands}
                                    searching={isSearching}
                                    clearBrand={clearBrand}
                                    categories={categories}
                                    category={categoryFilter}
                                    handleClearBrand={clearBrand}
                                    setCategories={setCategories}
                                    setCategory={setCategoryFilter}
                                    isAuthenticated={isAuthenticated}
                                    handleSetSearching={setSearching}
                                    setProducts={(hits) => setProducts(hits)}
                                    clearCategoryFilter={clearCategoryFilter}
                                />
                            </ProductsView>
                        </PrivateRoute>

                        <PrivateRoute exact path="/cart">
                            <Cart/>
                        </PrivateRoute>

                        <PrivateRoute exact path="/catalog">
                            <Catalog/>
                        </PrivateRoute>

                        <Route exact path="/orders/custno/:custno">
                            <OrdersCustno/>
                        </Route>

                        <Route path="/orders">
                            <OrderStatus/>
                        </Route>

                        <Route path="/order/:id">
                            <OrderDetail/>
                        </Route>

                        <Route path="/order-details">
                            <OrderDetail/>
                        </Route>
                        <Route path="/customers">
                            <Customers/>
                        </Route>

                        <Route path="/settings">
                            <Settings/>
                        </Route>

                        <Route path="/product/:id">
                            <ProductDetail/>
                        </Route>

                        <Route path="/customer-items/:custno">
                            <CustomerProducts setProducts={(hits) => setProducts(hits)}/>
                            <Products
                                brand={brand}
                                setBrand={setBrand}
                                products={products}
                                setBrands={setBrands}
                                searching={isSearching}
                                clearBrand={clearBrand}
                                categories={categories}
                                category={categoryFilter}
                                setCategories={setCategories}
                                handleClearBrand={clearBrand}
                                setCategory={setCategoryFilter}
                                handleSetSearching={setSearching}
                                isAuthenticated={isAuthenticated}
                                clearCategoryFilter={clearCategoryFilter}
                                setProducts={(hits) => setProducts(hits)}
                            />
                        </Route>

                        <PrivateRoute path="/">
                            <div className="relative">
                                {/*<Brands*/}
                                {/*    brand={brand}*/}
                                {/*    brands={brands}*/}
                                {/*    setBrand={setBrand}*/}
                                {/*    algoliaHits={products}*/}
                                {/*    searching={isSearching}*/}
                                {/*    handleSetSearching={setSearching}*/}
                                {/*    handleAlgoliaHits={(hits) => setProducts(hits)}*/}
                                {/*/>*/}
                                <Products
                                    brand={brand}
                                    products={products}
                                    setBrand={setBrand}
                                    setBrands={setBrands}
                                    searching={isSearching}
                                    clearBrand={clearBrand}
                                    categories={categories}
                                    category={categoryFilter}
                                    setCategories={setCategories}
                                    handleClearBrand={clearBrand}
                                    setCategory={setCategoryFilter}
                                    isAuthenticated={isAuthenticated}
                                    handleSetSearching={setSearching}
                                    setProducts={(hits) => setProducts(hits)}
                                    clearCategoryFilter={clearCategoryFilter}
                                    // handleAlgoliaHits={(hits) => setProducts(hits)}
                                    // setProducts={setProducts}
                                />
                            </div>
                        </PrivateRoute>
                    </Switch>
                </div>
                {isAuthenticated && <Footer/>}
            </div>
        </BrowserRouter>
    );
}

export default App;
