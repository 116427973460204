import axios from "axios";

async function getCustomers(user, setCustomers, setLoading) {
  setLoading(true);
  try {
    const auth = { headers: { Authorization: `Bearer ${user.token}` } };
    axios
      .get(`${process.env.REACT_APP_API}/orders/customers/${user.sl}`, auth)
      .then((data) => {
        setCustomers(data.data);

        setLoading(false);
      })
      .catch((e) => {
        console.error(e);
      });
  } catch (error) {
    alert(error); // catches both errors
  }
}

export default getCustomers;
