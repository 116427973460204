import _ from "lodash";
import classNames from "classnames";
import {Link} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {CatalogCategoryProducts} from "./CatalogCategoryProducts";
import {addCurrent, clearBrand} from "../redux/action-creators";

export default function CatalogCategory({category, viewAllNow}) {
    const dispatch = useDispatch();
    const {products, name, brands, children, siblings} = category;
    const categories = useSelector((state) => state.products.categoriesTree || []);
    const [selectedCategory, setSelectedCategory] = useState("");

    useEffect(() => {
        if (category.children.length)
            // if (false)
            // setSelectedCategory(category.children[0].name);
            setSelectedCategory("");

        else setSelectedCategory("");
    }, [category]);

    const handleSelectCategory = (name) => () => {
        dispatch(clearBrand());
        dispatch(addCurrent({selectedCategory: name}));
        setSelectedCategory(name);
    };

    return (
        <div className="pt-8 pl-8 pr-8">
            <>
                <div className="">
                    <h1
                        className="text-2xl text-gray-700 cursor-pointer"
                        onClick={handleSelectCategory("")}
                    >
                        {name}
                        <span className="text-xs ml-1">({products.length})</span>
                    </h1>
                    <div className="flex flex-wrap w-full">
                        {_.sortBy(
                            (children.length ? children : siblings),
                            "name"
                        )
                         // TODO for some reason added this filters
                         // .filter(s => s.name !== selectedCategory)
                         .map((s) => (
                            <div
                                key={s.id}
                                className={classNames("mr-3 cursor-pointer", {
                                    "text-red-900": selectedCategory === s.name,
                                    "is-orange": selectedCategory !== s.name,
                                })}
                                onClick={handleSelectCategory(s.name)}
                            >
                                <Link to={s.slug}>
                                    {s.name}
                                    <span className="text-xs text-gray-700 ml-1">
                                      ({categories[s.slug].products.length})
                                    </span>
                                </Link>

                            </div>
                        ))}
                    </div>
                </div>
                <CatalogCategoryProducts {...{products, selectedCategory}} />
            </>
        </div>
    );
}
