import {useSelector} from "react-redux";
import {useEffect, useState} from "react";

export function useProducts(props) {
  let {
    brand,
    products,
    category,
    searching,
    clearBrand,
    clearCategoryFilter,
  } = props;
  const navigationType = useSelector((state) => state.user.navigationType);
  const [filteredResults, setFilteredResults] = useState([]);

  useEffect(() => {
    if (brand) {
      if (searching) {
        clearCategoryFilter();
      }
      setFilteredResults(products.filter((s) => s.brand === brand));
    } else {
      setFilteredResults(products);
    }
  }, [brand, products]);

  useEffect(() => {
    if (brand) {
      setFilteredResults(products.filter((s) => s.brand === brand));
    } else {
      setFilteredResults(products);
    }
  }, [brand]);

  useEffect(() => {
    if (false) {
      setFilteredResults(
        products.filter((s) => s.categories.includes(category))
      );
    } else {
      setFilteredResults(products);
    }
  }, [category, products]);

  return {
    navigationType,
    filteredResults,
    clearCategoryFilter,
    clearBrand,
    category,
    brand,
    searching,
    };
  }
