import React from "react";
import {Redirect, Route} from "react-router";
import jwtDecode from "jwt-decode";
import {useDispatch, useSelector} from "react-redux";

import verifyToken from "./../../helpers/verfyToken";
import fetchProducts from "./../../redux/actions/products";
import fetchCategories from "../../redux/actions/fetchCategories";
import fetchProductsAndCategories from "../../redux/actions/fetchProductsAndCategories";

export default function PrivateRoute({children, ...rest}) {
    const dispatch = useDispatch();
    const token = useSelector(
        (state) => state.user.token || localStorage.token || null
    );
    const isAuthenticated = useSelector((state) => state.user.isAuthenticated);

    if (!isAuthenticated) {
        try {
            let decodedToken = jwtDecode(token);
            verifyToken(decodedToken);
            dispatch({
                type: "AUTH_RECEIVED",
                payload: {
                    token,
                    user: decodedToken.data,
                },
            });

            dispatch(fetchProductsAndCategories());
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <Route
            {...rest}
            render={({location}) =>
                isAuthenticated ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: "/login",
                            state: {from: location},
                        }}
                    />
                )
            }
        />
    );
}
