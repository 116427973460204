import React from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { preventPropogation } from './../helpers/helpers'
import Fade from "./animations/Fade";

const MyAccountNav = () => {
  return (
    <>
      <Link
        to={`/orders`}
        onClick={preventPropogation}
        style={{ borderColor: `#eb6433` }}
        className={classNames("text-gray-900 hover-orange", {
          "is-orange": false === true,
        })}
      >
        orders
      </Link>
      <Link
        to={`/customers`}
        onClick={preventPropogation}
        style={{ borderColor: `#eb6433` }}
        className={classNames("text-gray-900 hover-orange", {
          "is-orange": false === true,
        })}
      >
        customers
      </Link>
      <Link
        to={`/products`}
        onClick={preventPropogation}
        style={{ borderColor: `#eb6433` }}
        className={classNames("text-gray-900 hover-orange", {
          "is-orange": false === true,
        })}
      >
        products
      </Link>
      <Link
        to={`/settings`}
        onClick={preventPropogation}
        style={{ borderColor: `#eb6433` }}
        className={classNames("text-gray-900 hover-orange", {
          "is-orange": false === true,
        })}
      >
        settings
      </Link>
    </>
  );
};

export default MyAccountNav;
