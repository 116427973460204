import React from "react";
import { useDispatch, useSelector } from "react-redux";
import SubCategory from "./SubCategory";
import _ from "lodash";
import algoliasearch from "algoliasearch";
import { setSubcategories } from "../redux/actions/setSubcategories";
import { setUniqueBrands } from "../helpers";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { useRecoilState } from "recoil";
import { subMenuTypeAtom, categoryAtom } from "../project/atoms";
import { preventPropogation } from "./../helpers/helpers";
import { clearZreissProducts } from "../redux/action-creators/products";
import { clearBrand } from "../redux/action-creators";

const client = algoliasearch("9M92AFELTS", "9fc580b99a078a341ab22d0796a53ebc");
const index = client.initIndex("products");
index.setSettings({
  hitsPerPage: 200,
});

const SearchCategoriesFilter = ({
  isAuthenticated,
  searching,
  categories,
  category,
  setProducts,
  setCategory,
  setBrands,
  clearCategoryFilter,
  handleClearBrand,
  handleSetSearching,
}) => {
  let dispatch = useDispatch();
  const [, setMode] = useRecoilState(subMenuTypeAtom);
  const [, setCategoryAtom] = useRecoilState(categoryAtom);
  // const [category, setCategory] = React.useState();
  const searchCategory = (category) => {
    setMode("search");
    clearCategoryFilter();

    handleClearBrand();
    setCategory(category);

    setCategoryAtom(category);
    dispatch(clearZreissProducts());
  };

  if (_.isEmpty(categories)) return null;
  return (
    <>
      {_.chunk(
        categories,
        categories.length > 10
          ? categories.length > 20
            ? 12
            : categories.length > 13
            ? 6
            : 4
          : categories.length > 2
          ? 2
          : 1
      ).map((a) => (
        <div className={"flex flex-col items-center "}>
          <div className={"other-category text-black"}>
            {a.map((s) => (
              <li>
                <Link
                  style={{ textTransform: `none` }}
                  onClick={(e) => {
                    searchCategory(s);
                    dispatch(clearBrand());
                    preventPropogation(e);
                  }}
                  key={s}
                  className={classNames("text-gray-900 hover-orange", {
                    "is-orange": s === category,
                  })}
                >
                  {s}
                </Link>
              </li>
            ))}
          </div>
        </div>
      ))}
    </>
  );
};

export default SearchCategoriesFilter;
