import App from "./App";
import axios from "axios";
import React from "react";
import moment from "moment";
import lodash from "lodash";
import {DateTime} from "luxon";
import ReactDOM from "react-dom";
import store from "./redux/store";
import {RecoilRoot} from "recoil";
import LogRocket from "logrocket";
import {Provider} from "react-redux";

import "./index.css";

const user = localStorage.user || false;
if (process.env.NODE_ENV == "production" && user) {
  LogRocket.init("l32utd/app", {
    release: process.env.REACT_APP_VERSION,
  });
  const email = JSON.parse(user).user.email;
  LogRocket.identify(email);
}


// import './helpers/prototypes'
(function () {
  console.log(process.env);
  window.store = store;
  window.DateTIme = DateTime;
  window._ = lodash;
  window.axios = axios;
  window.moment = moment;
  window.logout = function () {
    delete localStorage.token;
    delete localStorage.products;    
    window.location.reload();
  };
})();

console.log("Version: " + process.env.REACT_APP_VERSION);

ReactDOM.render(
  <Provider store={store}>
    <RecoilRoot>
      <App />
    </RecoilRoot>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register();
