// import StoreIt from './../../data/StoreIt'
// let products = StoreIt.get('products');

import produce from "immer";

let localProducts = localStorage.products;
let localCategoriesTree = localStorage.categoriesTree;

const initialState = {
  fetching: false,
  fetched: false,
  error: null,
  products: localProducts ? JSON.parse(localProducts) : false,
  categoriesTree: localCategoriesTree ? JSON.parse(localCategoriesTree) : false,
  zreissProducts: [],
  searchResults: {},
  serverUpdated: "",
};
export function products(state = initialState, action) {
  switch (action.type) {
    case "FETCH_PRODUCT_AND_CATEGORIES_START": {
      return {
        ...state,
        fetching: true,
        fetched: false,
        failed: false,
      };
    }
    case "FETCH_PRODUCTS_STOP": {
      return {
        ...state,
        fetching: false,
      };
    }
    case "FETCH_PRODUCTS_FAILED": {
      return {
        ...state,
        fetching: false,
        fetched: false,
        failed: true,
      };
    }
    case "LOCAL_PRODUCTS_READY":
    case "FETCH_PRODUCTS_RECEIVED": {
      return {
        ...state,
        products: action.payload,
      };
    }
    case "PRODUCTS_SERVER_UPDATE": {
      return {
        ...state,
        serverUpdated: action.payload,
        fetching: false,
      };
    }
    case "FETCH_ZREISS_PRODUCTS_RECEIVED": {
      return produce(state, (draft) => {
        draft.zreissProducts = action.payload;
      });
    }

    case "FETCH_CATEGORIES_RECEIVED": {
      return {
        ...state,
        categoriesTree: action.payload,
      };
    }

    case "CLEAR_ZREISS_PRODUCTS": {
      return produce(state, (draft) => {
        draft.zreissProducts = [];
      });
    }
    case "FETCH_PRODUCT_AND_CATEGORIES_RECEIVED": {
      const { categories: categoriesTree, products } = action.payload;
      return {
        ...state,
        products,
      };
    }
    case "SEARCH_READY": {
      return {
        ...state,
        searchResults: action.payload,
      };
    }
    case "UPDATE_PRODUCTS_FILTER": {
      return {
        ...state,
        filterText: action.payload,
      };
    }
    default:
      return state;
  }
}
export const getItems = (state) => state.products.products;
