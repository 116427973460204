import React from "react";
import {Link} from "react-router-dom";
import {DateTime} from "luxon";

const Order = ({ order, selectOrder, index, customer }) => {
  let hr = false;
  if (index && !(index % 10)) {
    hr = true;
  }

  return (
    <>
      {hr && <hr className="border border-orange-400"></hr>}
      <Link to={`/order/${order.id}`}>
        <div
          className={
            "items-center p-4 flex w-3/4 m-auto justify-between text-gray-700 text-xl"
          }
          // onClick={selectOrder}
        >
          <div className="flex flex-col w-2/12 select-none">
            <div className={"flex flex-col is-orange select-none"}>
              <span className="text-xs text-gray-700">
                {DateTime.fromISO(order.updated_at).toRelative()}
              </span>
              {order.custno}
            </div>
          </div>
          {!customer && order.customer && (
            <div className="flex flex-col text-center select-none">
              <span className="text-xs">view orders</span>
              <Link to={`/orders/custno/${order.custno}`}>{order.custno}</Link>
            </div>
          )}
          <div className="w-2/12 text-center flex-1 select-none">
            ${order.total.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}
          </div>
          <div className="flex flex-col justify-center items-center w-5/12 select-none text-xs">
            {order.note && <h6 className="text-xs">{order.note}</h6>}
          </div>
          {!customer && order.custno && (
            <Link to={`/orders/custno/${order.custno}`}>
              <div className={"flex flex-col is-orange select-none"}>
                <span className="text-xs text-gray-700">View Orders</span>
                {order.custno}
              </div>
            </Link>
          )}
        </div>
      </Link>
    </>
  );
};

export default Order;
