const current = {
  type: "filter",
  brandName: "",
  selectedCategory:""
};

const category = {
  category: "",
  subCategories: "",
};

const initialState = {
  ...current,
};

export default function data(state = initialState, action) {
  switch (action.type) {
    case "CURRENT_OBJECT":
      return {
        ...state,
        ...action.payload,
      };

    case "ADD_CURRENT_OBJECTS": {
      return {
        ...state,
        ...action.payload,
      };
    }

    case "CURRENT_ACTIVE_OBJECTS_CLEAR": {
      return {
        ...state,
        ...current,
      };
    }

    default:
      return state;
  }
}
