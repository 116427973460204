class Zreiss{
	static getApi(){
		const HOST = 'zreiss.com';
		const API = '/api/sales';
		return `https://${HOST}${API}`;
	}
	static getProducts(){
		var url = `${this.getApi()}/products`;
		return fetch(url)
		  .then((resp) => resp.json())
	}

	static getOrders(sl){
		var url = `${this.getApi()}/orders/${sl}`;
		return fetch(url)
		.then((resp) => resp.json())
	}

	static getOrderDetail(orderId){
		var url = `${this.getApi()}/order-detail/${orderId}`;
		return fetch(url)
		  .then((resp) => resp.json())
	}

	static getItemsPopular(sl){
		var url = `${this.getApi()}/items-popular/${sl}`;
		return fetch(url)
		  .then((resp) => resp.json())
	}

	static getCustomersRecent(sl){
		var url = `${this.getApi()}/customers-recent/${sl}`;
		return fetch(url)
		  .then((resp) => resp.json())
	}

	static getCustomers(sl){
		var url = `${this.getApi()}/customers/${sl}`;
		return fetch(url)
		  .then((resp) => resp.json())
	}

	static submitOrder(order , sl){
		var url = `${this.getApi()}/order/${sl}`;
		
		return fetch(url ,  {
		    method: 'post',
		    body: JSON.stringify(order)
		  }).then(function(response) {
		    return response.json();
		  });
	}
	
	static calculateTotal(products){
		return products.reduce((total, product) => total + product.qty * product.price, 0).toFixed(2);
	}
	static calculateZreissTotal(products){
		return products.reduce((total, product) => 
			total + product.qty * (product.product.price ? parseFloat(product.product.price) : product.price ) , 0)
			.toFixed(2);	
		}

	static saveOrder(order,name){
		localStorage.setItem(name, JSON.stringify(order));
		this.addOrdertoSavedOrdersList(name);
	}

	static getSavedOrder( name ){
		return JSON.parse(localStorage.getItem(name));
	}

	static getSavedOrdersList(){
		return JSON.parse(localStorage.getItem('orders')) || [];
	}

	static addOrdertoSavedOrdersList(name){
		if( name ){
			var orders = this.getSavedOrdersList();
			orders.push(name);
			localStorage.setItem('orders' , JSON.stringify(orders));			
		}
	}
}

export default Zreiss