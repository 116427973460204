import store from "../store";

export function addCurrent(object) {
  return {
    type: "CURRENT_OBJECT",
    payload: object,
  };
}

export function clearBrand() {
  return {
    type: "CURRENT_OBJECT",
    payload: { brandName: "" },
  };
}

export function addCurrentObject(type, object) {
  return {
    type: "CURRENT_OBJECT",
    payload: { [type]: object },
  };
}

export function addNewCurrentActiveObjects(type, object) {
  const current = [...store.getState().current[type]];
  current.push(object);
  return {
    type: "CURRENT_OBJECT",
    payload: { [type]: current },
  };
}

export function addCurrentSelectedObjects(
  objectId,
  objectType,
  currentSelectedObjects
) {
  let newState = { ...currentSelectedObjects };
  if (currentSelectedObjects[objectId]) {
    delete newState[objectId];
  } else {
    newState[objectId] = {
      objectId,
      objectType,
    };
  }

  return {
    type: "CURRENT_OBJECT",
    payload: {
      currentSelectedObjects: newState,
      currentSelectedObjectsCount: Object.keys(newState).length,
    },
  };
}

export function clearObjects() {
  return {
    type: "CURRENT_OBJECTS_CLEAR",
  };
}

export function clearActiveObjects() {
  return {
    type: "CURRENT_ACTIVE_OBJECTS_CLEAR",
  };
}
