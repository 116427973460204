import React from "react";
import Fade from "./animations/Fade";
import SearchProduct from "./SearchProduct";
import {useDispatch, useSelector} from "react-redux";
import {addToOrder} from "../redux/actions/addToOrder";
import ZreissSearchProduct from "./AS400SearchProduct";

export default function ProductsList({algoliaHits}) {
    const dispatch = useDispatch();
    const zreissProducts = useSelector((state) => state.products.zreissProducts);

    const addProductToOrder = (product, qty, note) => {
        dispatch(addToOrder(product, qty, note));
    };

    if (zreissProducts.length > 0) {
        return (
            <Fade>
                {zreissProducts.map((s, index) => {
                        return (
                            <ZreissSearchProduct
                                key={"p" + s.id ? s.id : s.ITEMNO}
                                cursor={true}
                                index={index}
                                zreissProduct={s.zreissProduct?.id ? s.zreissProduct : s}
                                handleAddProductToOrder={addProductToOrder}
                            />
                        )
                })}
            </Fade>
        );
    }
    return (
        <>
            {algoliaHits.length > 0 && (
                <Fade>
                    {algoliaHits.length > 0 &&
                        algoliaHits
                            // .slice(0, 100)
                            .map((s, index) => {
                                if (s.id || s.zreissProduct.id) {
                                    return (
                                        <SearchProduct
                                            {...s}
                                            key={"p" + s.id}
                                            cursor={true}
                                            index={index}
                                            handleAddProductToOrder={addProductToOrder}
                                        />
                                    )
                                }
                                return <h1>no</h1>
                            })}
                </Fade>
            )}
        </>
    );
}
