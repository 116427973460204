import _ from "lodash";
import store from "../redux/store";

export const setUniqueCategories = (hits, setCategories) => {
  let products = store.getState().products.products;
  new Promise(function (resolve, reject) {
    let categories = _.uniq(
      _.flatten(hits.filter((s) => products[s.id]).map((s) => s.categories))
    );
    resolve(categories);
  }).then((categories) => setCategories(categories));
};

export const setUniqueBrands = (hits, setBrands) => {
  let products = store.getState().products.products;
  new Promise(function (resolve, reject) {
    let brands = _.uniq(hits.filter((s) => products[s.id]).map((s) => s.brand));
    resolve(brands);
  }).then((brands) => setBrands(brands));
};
