import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useHistory, useLocation } from "react-router";
import auth from "../redux/actions/auth";
import { useRecoilState } from "recoil";
import { dataUpdatedAtom } from "../project/atoms";

export default function () {
  let history = useHistory();
  let location = useLocation();
  const dispatch = useDispatch();

  const isAuthenticated = useSelector((state) => state.user.isAuthenticated);

  const [, setDataUpdated] = useRecoilState(dataUpdatedAtom);
  let [username, setUsername] = useState(
    localStorage.user ? localStorage.user.email : ""
  );
  let [secret, setSecret] = useState("");
  let { from } = location.state || { from: { pathname: "/" } };
  let login = (setProducts) => {
    dispatch(auth(username, secret, from, history, setDataUpdated));
    // history.replace(from);
  };
  if (isAuthenticated) {
    return <Redirect to={"/"} />;
  }
  return (
    <div
      id=""
      className="fixed w-full h-full"
      style={{ backgroundColor: `rgb(0,0,0,0.68` }}
    >
      <div id="login" className="fixed text-center flex lg:justify-center">
        <div id="" style={{ width: `292px` }}>
          <img
            src="https://s3.amazonaws.com/magento-luzerne/zreiss/zreiss+white.png"
            alt=""
            style={{ margin: `0 auto` }}
          />

          <form
            className=""
            onSubmit={(event) => {
              login({ username, secret });
              event.preventDefault();
            }}
          >
            <p className="control mt-3">
              <input
                className="input"
                type="email"
                placeholder="E-mail Address"
                name="email"
                required="true"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </p>
            <p className="control">
              <input
                className="input"
                type="password"
                placeholder="Password"
                name="password"
                required="true"
                onChange={(e) => setSecret(e.target.value)}
              />
            </p>
            {/*<a href="password/reset" className="">FORGOT PASSWORD</a>*/}
            <p className="control">
              <button
                className="button is-success is-full-width h-8 w-full mt-2 mb-2"
                type={"submit"}
              >
                Login
              </button>
            </p>
            <p className="control">
              <a
                href="get-access"
                id="access"
                className="button is-primary is-full-width"
              >
                {/*<span id="access-message">ALREADY A CUSTOMER</span>GET ACCESS*/}
              </a>
            </p>
            <p className="control">
              <a
                href="become-customer"
                id="bc-container"
                className="button is-primary is-full-width"
              >
                {/*BECOME<span id="bc">A ZREISS CUSTOMER</span>*/}
              </a>
            </p>
          </form>
        </div>
      </div>
    </div>
  );
}
