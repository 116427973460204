import { addUser } from "../../redux/action-creators/user";

export const getNavigationType = (navigationType) => {
  return navigationType === "menu"
    ? "mini"
    : navigationType === "mini"
    ? "catalog"
    : "menu";
};

export const changeNavigation = (navigationType, dispatch) => () => {
  const newNavigation = getNavigationType(navigationType);
  localStorage.navigationType = newNavigation;

  dispatch(
    addUser({
      navigationType: newNavigation,
    })
  );
};
