import User from "./User";
import { NavCart } from "./NavCart";
import { useRecoilState } from "recoil";
import { withRouter } from "react-router";
import { useDebounce } from "use-debounce";
import algoliasearch from "algoliasearch";
import { ClipLoader } from "react-spinners";
import { subMenuTypeAtom } from "../project/atoms";
import searchAll from "../redux/actions/searchAll";
import { changeNavigation } from "./project/toggles";
import { useDispatch, useSelector } from "react-redux";
import { clearBrand } from "../redux/action-creators/current";
import React, { useState, useEffect, createRef } from "react";
import { setUniqueBrands, setUniqueCategories } from "../helpers";
import { clearZreissProducts } from "../redux/action-creators/products";

const client = algoliasearch("9M92AFELTS", "9fc580b99a078a341ab22d0796a53ebc");

const index = client.initIndex("products");
index.setSettings({
  hitsPerPage: 200,
});

function Nav({
  history,
  setProducts,
  handleSetSearching,
  handleClearBrand,
  setCategories,
  setBrands,
}) {
  let textInput = createRef();
  const dispatch = useDispatch();

  const [search, setSearch] = useState("");
  const [isZreissSearching, setIsZreissSearching] = useState(false);
  const [isAlgoliaSearching, setIsAlgoliaSearching] = useState(false);

  const [debouncedText] = useDebounce(search, 205);

  const [, setSubmenuType] = useRecoilState(subMenuTypeAtom);

  const navigationType = useSelector((state) => state.user.navigationType);
  const isAuthenticated = useSelector((state) => state.user.isAuthenticated);

  const brandName = useSelector((state) => state.current.brandName);

  useEffect(() => {}, [isAuthenticated]);

  useEffect(() => {
    if (history.location.pathname !== "/") {
      history.push("/");
    }

    if (search.length > 1) {
      if(brandName) {
        dispatch(clearBrand());
      }
      handleSetSearching(true);
      setIsZreissSearching(false);
      setIsAlgoliaSearching(true);
      index.search({ query: search }, (err, { hits } = {}) => {
        if (err) throw err;
        setIsAlgoliaSearching(false);
        setProducts(hits);

        setUniqueCategories(hits, setCategories);
        setUniqueBrands(hits, setBrands);
        setSubmenuType("subCategories");

        handleClearBrand();
        
      });
      // TODO get all categories
    }
  }, [debouncedText]);
  const handleSearchEnter = (e) => {
    if (e.key === "Enter" && search.length > 2) {
      setIsAlgoliaSearching(false);
      setIsZreissSearching(true);
      handleSearchAll();
    }
  };
  const handleSearchAll = () => {
    dispatch(searchAll(search, setProducts, setIsZreissSearching));
    history.push("/");
  };

  return (
    <nav className="no-print">
      <img
        alt="zreiss"
        className="select-none"
        src="https://s3.amazonaws.com/magento-luzerne/zreiss/logo.png"
        style={{ maxHeight: `76px` }}
        onClick={changeNavigation(navigationType, dispatch)}
      />
      {isAuthenticated && (
        <div className={"flex flex-col"}>
          <div className={"flex justify-end"}>
            <User />
            <NavCart />
          </div>
          <div className="flex mt-2 relative justify-end">
            <input
              ref={textInput}
              id={"search"}
              className="border-0"
              style={{ width: `250px` }}
              placeholder={"Search..."}
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
                dispatch(clearZreissProducts());
              }}
              onKeyPress={handleSearchEnter}
            />
            {search.length > 0 && (
              <h3
                id={"search-button"}
                tabIndex={"0"}
                className="absolute text-gray-700 cursor-pointer"
                style={{ right: `49px`, top: `4px` }}
                onKeyDown={() => {
                  setSearch("");
                  textInput.current.focus();
                  handleSetSearching(false);
                }}
                onClick={() => {
                  setSearch("");
                  textInput.current.focus();
                  handleSetSearching(false);
                }}
              >
                {!isAlgoliaSearching && !isZreissSearching ? (
                  <span role={"img"} aria-label={"clear-search"}>
                    ❌
                  </span>
                ) : (
                  <ClipLoader
                    color={isAlgoliaSearching ? "#eb6433" : "black"}
                    size={25}
                    cssOverride={{}}
                  />
                )}
              </h3>
            )}
            <button className="border-0">
              <img
                alt={"search"}
                src={"/baseline_search_white_18dp.png"}
                style={{ width: `25px` }}
              />
            </button>
          </div>
        </div>
      )}
    </nav>
  );
}

export default withRouter(Nav);
