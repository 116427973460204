export const getProductPrice = (product)=>{
    return {
        isSaleProduct: product.sale_price > 0,
        isClearanceProduct: product.status === "C" && product.clearance_price,
    }
}

export const getLowestPriceWithType = (product, qty, cart)=>{
    let {price, sale_price, clearance_price, status, sale_min_order_total, sale_min_quantity} = product;
    let regularTotal = Object.values(cart.products).reduce(
        (sum, item) => sum + parseInt(item.qty) * item.regularPrice,
        0
    );
    regularTotal += product.price;

    let qualifiedForSale = sale_price && (qty >= sale_min_quantity && regularTotal >= sale_min_order_total);
    let prices = [
        {
            priceType: "price",
            price: price
        },
        {
            priceType: "clearance",
            price: status === "C" && clearance_price || 0
        },
        {
            priceType: "sale",
            price: qualifiedForSale ? sale_price : 0
        },
    ];


    let minPriceTypeObj = prices.filter(s=>s.price && s.price>0)
        .reduce((a, b) => {
            return {
                priceType: a.price < b.price ? a.priceType : b.priceType,
                price:Math.min(a.price, b.price)
            }
        })


    return {...minPriceTypeObj, regularPrice: product.price}
}

