import React from "react";
import { useDispatch } from "react-redux";
import { Redirect } from "react-router";

const Logout = () => {
  const dispatch = useDispatch();
  // const [toHome, setToHome] = React.useState(false);

  React.useEffect(() => {
    delete localStorage.user;
    delete localStorage.token;
    dispatch({ type: "RESET_AUTH" });
    // setToHome(true);
  });

  return <Redirect to="/" />;
};
export default Logout;
