import React from "react";
import {useLocation} from "react-router";
import { useSelector } from "react-redux";
import CatalogCategory from "./CatalogCategory";

export default function Catalog({ algoliaHits = [] }) {
  const location = useLocation()
  const categorySlug = location.pathname.substring(1) || "electronics"
  const categories = useSelector((state) => state.products.categoriesTree || []);

  if (!categories[categorySlug]) {
    return null;
  }

  return (
    <div className="pb-16">
      <CatalogCategory products={algoliaHits} category={categories[categorySlug]} viewAllNow={true} />
    </div>
  );
}
