import Zreiss from "./../../helpers/zreiss";
import findIndex from "lodash/findIndex";

const localCart = (localStorage.cart && JSON.parse(localStorage.cart)) || {};
const initialState = {
    products: localCart.products || {},
    total: localCart.total || 0,
    customer: localCart.customer || {},
    note: localCart.note || "",
};

const emptyCart = {
    products: {},
    total: 0,
    customer: {},
};

export function cart(state = initialState, action) {
    switch (action.type) {
        case "CART_LOAD": {
            return JSON.parse(localStorage.cart);
        }
        case "CART_ACTIVATE_ORDER": {
            return action.payload;
        }

        case "CART_PRODUCTS": {
            let {id, object} = action.payload;
            let newState = {...state};

            if (object.qty == "0") {
                delete newState.products[id];
            } else {
                newState.products[id] = object;
            }

            const regularTotal = Object.values(newState.products).reduce(
                (sum, item) => sum + parseInt(item.qty) * item.regularPrice,
                0
            );

            let failedSalePricedItems = false;
            Object.values(newState.products).forEach((item) => {
                let saleMessage = "";
                const {sale_price, sale_min_quantity, sale_min_order_total, qty, clearance_price, status} = item;

                let qualifiesForSale = sale_price && (qty >= sale_min_quantity && regularTotal >= sale_min_order_total);

                let isClearance = status === "C" && clearance_price > 0;
                if(isClearance){
                    item.priceType = "clearance";
                    item.price = clearance_price;
                }
                if (qualifiesForSale) {
                    item.saleMessage = "";
                    item.price = sale_price;
                    item.priceType = "sale";

                    if(isClearance && sale_price > clearance_price) {
                        item.priceType = "clearance";
                        item.price = clearance_price;
                    }
                } else {
                    if (sale_price) {
                        if (qty < sale_min_quantity) {
                            saleMessage = `item qty must be ${sale_min_quantity}`;
                        }
                        if (sale_min_order_total && !(regularTotal >= sale_min_order_total)) {
                            saleMessage += `${saleMessage && ", "} order total of regular item price must be $${sale_min_order_total}`;
                        }
                        failedSalePricedItems = true;
                    }

                    if(!isClearance || item.price < clearance_price) {
                        item.priceType = "price";
                        item.price = item.regularPrice;
                    }

                    item.saleMessage = saleMessage;
                }
            });

            const total = Object.values(newState.products).reduce(
                (sum, item) => sum + parseInt(item.qty) * item.price,
                0
            );
            newState.total = total;
            newState.regularTotal = failedSalePricedItems ? regularTotal : 0;
            localStorage.cart = JSON.stringify(newState);

            return newState;
        }
        case "CART_LOAD_HEADER": {
            let newState = {...state, ...action.payload};
            return newState;
        }
        case "CART_CLEAR_PRODUCTS": {
            let newState = {...state};

            newState.products = [];
            localStorage.cart = JSON.stringify(newState);

            return newState;
        }
        case "CART_LOAD_PRODUCTS": {
            let {products} = action.payload;
            let newState = {...state};

            newState.products = products;
            localStorage.cart = JSON.stringify(newState);

            return newState;
        }
        case "CART_ADD_PRODUCT": {
            return addProduct(state, action);
        }

        case "CART_REMOVE_PRODUCT": {
            // return removeProduct(state, action);
            const newState = {...state};
            delete newState.products[action.payload];

            localStorage.cart = JSON.stringify(newState);

            return newState;
        }

        case "CART_ADD_CUSTOMER": {
            const newState = {...state};
            newState.customer = action.payload;
            localStorage.cart = JSON.stringify(newState);
            return newState;
        }

        case "CART_SET_SHIPTO": {
            return {
                ...state,
                shipto: action.payload,
            };
        }

        case "CART_UPDATE_ORDER_EMAIL": {
            return {
                ...state,
                email: action.payload,
            };
        }

        case "CART_UPDATE_ORDER_CUST_PO": {
            return {
                ...state,
                customer_po: action.payload,
            };
        }

        case "CART_UPDATE_ORDER_NOTE": {
            return {
                ...state,
                note: action.payload,
            };
        }

        case "CART_SUBMIT_ORDER_START": {
            return {
                ...state,
                status: "started",
            };
        }

        case "CART_SUBMIT_ORDER_SUCCEEDED": {
            return {
                ...state,
                status: "succeeded",
            };
        }

        case "CART_SUBMIT_ORDER_FAILED": {
            return {
                ...state,
                status: "failed",
            };
        }

        case "CART_RESET": {
            return {
                products: {},
                total: 0,
                customer: {},
                note: "",
            };
        }
        default:
            return state;
    }
}

function addProduct(state, action) {
    const order = state;
    const items = order.products;
    const product = action.product;
    const index = findIndex(items, {itemno: product.product.itemno});

    if (index !== -1) {
        items[index] = product.product;
    } else {
        items.push(product.product);
    }

    order.products = items;
    order.total = Zreiss.calculateZreissTotal(items);
    localStorage.setItem("current-order", JSON.stringify(order));
    return {
        ...state,
        products: order.products,
        total: order.total,
    };
}

export const getItems = (state) => state.products.products;
