import { addCurrent } from "../action-creators";
import _ from "lodash";
import { search } from "./search";

export function setSubcategories(newCategory, addSubCategories) {
  return (dispatch, getState) => {
    // dispatch(search(newCategory.name));
    let store = getState();
    let categories = store.products.categoriesTree;
    let currentSubcategories = store.current.subCategories;

    let category = categories[newCategory.slug];
    let subCategories = _.isEmpty(category.children)
      ? currentSubcategories
      : category.children;

    dispatch(
      addCurrent({
        category,
        subCategories,
      })
    );
    addSubCategories(subCategories);
  };
}
