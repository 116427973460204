export function addOrderToCart(order) {
  return (dispatch, getState) => {

    const allProducts = getState().products.products;
    let { items, customer, ...header } = order;

    dispatch({ type: "CART_CLEAR_PRODUCTS" });

    let products = {};
    order.items.forEach((orderItem) => {
      let info = {};
      if (!orderItem.product) {
        return null;
      }

      if (!allProducts[orderItem.product.id]) {
        info = orderItem.product;
      }
      let { itemno, qty, price, priceType, note } = orderItem;
      products[orderItem.product.id] = {
        id: orderItem.product.id,
        itemno,
        qty,
        price,
        priceType,
        note,
        info,
      };
    });
    delete order.items;
    let cartOrder = {
      ...order,
      products,
      customer,
    };

    localStorage.cart = JSON.stringify({ ...cartOrder });
    dispatch({
      type: "CART_ACTIVATE_ORDER",
      payload: {
        ...cartOrder,
      },
    });
  };
}
