const initialState = {
  showMyAccountNav: false,
};

export default function data(state = initialState, action) {
  switch (action.type) {
    case "SHOW":
      return {
        ...state,
        ...action.payload,
      };

    case "SHOW_CLEAR": {
      return {
        ...state,
        ...{
          showMyAccountNav: false,
        },
      };
    }

    default:
      return state;
  }
}
