import React from "react";
import Catalog from "./Catalog";
import {useProducts} from "./Products.hook";
import ProductsList from "./SearchProducts";
import CategoryBreadcrumb from "./CategoryBreadcrumb";
import {CatalogCategoryProducts} from "./CatalogCategoryProducts";
import { algoliaHits } from "../project/atoms";

export default function Products(props) {
  let {
    brand,
    category,
    searching,
    navigationType,
    filteredResults,
    
    clearBrand,
    clearCategoryFilter,
    } = useProducts(props);

  if (
    (navigationType === "catalog" || navigationType === "mini") && !searching  ) {
    return <CatalogCategoryProducts {...{products: filteredResults, selectedCategory: ""}} />
  }
  return (
    <div className={"relative"}>
      <CategoryBreadcrumb {...props} />
      {category && (
        <h2 className="text-3xl text-gray-900 m-4">
          {category}
          <span
            className="ml-3 text-sm text-gray-700 cursor-pointer"
            onClick={() => {
              clearCategoryFilter();
              clearBrand();
            }}
          >
            view all
          </span>
        </h2>
      )}
      {brand && (
        <h2 className="text-3xl text-gray-900 m-4">
          {brand}
          <span
            className="ml-3 text-sm text-gray-700 cursor-pointer"
            onClick={() => {
              clearCategoryFilter();
              clearBrand();
            }}
          >
            view all
          </span>
        </h2>
      )}
      {(props.products.length > 0 && (
        <ProductsList algoliaHits={filteredResults} />
      )) ||
        null}
    </div>
  );
}
