// import StoreIt from '../../data/StoreIt';
import axios from "axios";
const getLocalProducts = () => {
  return localStorage.products ? JSON.parse(localStorage.products) : false;
};

const getProductsObjectFromArray = (products) => {
  let productsObject = {};
  products.forEach((s) => (productsObject[s.id] = s));
  return productsObject;
};

export default () => {
  return (dispatch, getState) => {
    let productsObject = getLocalProducts();

    if (productsObject) {
      dispatch({ type: "LOCAL_PRODUCTS_READY", payload: productsObject });
      // return true;
    }
    dispatch({ type: "FETCH_PRODUCTS_START" });
    let token = getState().user.token;
    const auth = { headers: { Authorization: `Bearer ${token}` } };

    dispatch({ type: "FETCH_PRODUCTS_START" });
    axios
      .get(`${process.env.REACT_APP_API}/products`, auth)
      .then((data) => {
        const productsObject = getProductsObjectFromArray(
          JSON.parse(data.data)
        );
        dispatch({
          type: "FETCH_PRODUCTS_RECEIVED",
          payload: productsObject,
        });
        dispatch({ type: "FETCH_PRODUCTS_STOP" });
      })
      .catch((e) => {
        console.error(e);
        dispatch({ type: "FETCH_PRODUCTS_STOP" });
        dispatch({ type: "FETCH_PRODUCTS_FAILED" });
      });
  };
};
