import axios from "axios";
import store from "../../redux/store";

async function getOrdersCustno(custno) {
  const user = store.getState().user;

  try {
    const auth = { headers: { Authorization: `Bearer ${user.token}` } };
    const data = await axios.get(
      `${process.env.REACT_APP_API}/customers/${custno}/orders/${user.sl}`,
      auth
    );
    return data.data;
  } catch (error) {
    alert(error); // catches both errors
  }
}

export default getOrdersCustno;
