import axios from "axios";
import { DateTime } from "luxon";
import fetchProductsAndCategories from "./fetchProductsAndCategories";

export default function auth(email, password, from, history, setStore) {
  return (dispatch) => {
    axios
      .post(`${process.env.REACT_APP_API}/auth`, {
        email,
        password,
      })
      .then(({ data }) => {
        dispatch({
          type: "AUTH_RECEIVED",
          payload: data,
        });

        localStorage.token = data.token;

        dispatch(fetchProductsAndCategories());

        const now = DateTime.local().toLocaleString(
          DateTime.DATETIME_SHORT_WITH_SECONDS
        );
        localStorage.dataUpdated = now;
        setStore(now);

        history.replace(from);
      })
      .catch((e) => {
        console.error(e);
        dispatch({ type: "FETCH_PRODUCTS_STOP" });
        dispatch({ type: "FETCH_PRODUCTS_FAILED" });
      });
  };
}
