import axios from "axios";
import {DateTime} from "luxon";

export default (setStore) => {
    return (dispatch, getState) => {
        dispatch({type: "FETCH_PRODUCT_AND_CATEGORIES_START"});
        let token = getState().user.token;
        const auth = {headers: {Authorization: `Bearer ${token}`}};

        console.time("get products and categories");
        axios
            .get(`${process.env.REACT_APP_API}/cached-products-categories`, auth)
            .then((data) => {
                const {products, categories, serverUpdated} = data.data;

                dispatch({
                    type: "FETCH_CATEGORIES_RECEIVED",
                    payload: categories,
                });

                const productsObject = products.reduce((acc, product) => {
                    acc[product.id] = product;
                    return acc;
                }, {});

                dispatch({
                    type: "FETCH_PRODUCTS_RECEIVED",
                    payload: productsObject,
                });

                const updated = DateTime.fromISO(serverUpdated).toLocaleString(
                    DateTime.DATETIME_SHORT_WITH_SECONDS
                );

                dispatch({
                    type: "PRODUCTS_SERVER_UPDATE",
                    payload: updated,
                });

                const startCategoryProducts = categories["electronics"].products.slice(0, 20);
                const startProducts = startCategoryProducts.map(s => productsObject[s.id]);

                localStorage.categories = JSON.stringify(categories);
                localStorage.products = JSON.stringify(productsObject);
                localStorage.startProducts = JSON.stringify(startProducts);
            })
            .catch((e) => {
                console.error(e);
                dispatch({type: "FETCH_PRODUCT_AND_CATEGORIES_STOP"});
                dispatch({type: "FETCH_PRODUCT_AND_CATEGORIES_FAILED"});
            });
    };
};
