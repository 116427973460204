import React from "react";
import classNames from "classnames";
import { useProductsView } from "./ProductsView.hook";

export const ProductsView = ({
  children,
  setProducts,
  setCategory,
  handleClearBrand,
  handleSetSearching,
}) => {
  let { setCategorySearch, categorySearch } = useProductsView({
    setProducts,
    setCategory,
    handleClearBrand,
    handleSetSearching,
  });

  return (
    <>
      <div className="cursor-pointer m-4 flex justify-around w-7/8 p-4 items-center bg-gray-200 shadow-md uppercase font-semibold">
        <h1
          onClick={() => {
            setCategorySearch("back-in-stock");
          }}
          style={{ borderColor: `#eb6433` }}
          className={classNames(
            "nav-item active electronics is-orange hover-orange",
            {
              "is-orange orange-border border-b-2":
                "back-in-stock" === categorySearch,
            }
          )}
        >
          back in stock
        </h1>
        <h1
          onClick={() => {
            setCategorySearch("new");
          }}
          style={{ borderColor: `#eb6433` }}
          className={classNames(
            "nav-item active electronics is-orange hover-orange",
            {
              "is-orange orange-border border-b-2": "new" === categorySearch,
            }
          )}
        >
          new
        </h1>
        <h1
          onClick={() => {
            setCategorySearch("clearance");
          }}
          style={{ borderColor: `#eb6433` }}
          className={classNames(
            "nav-item active electronics is-orange hover-orange",
            {
              "is-orange orange-border border-b-2":
                "clearance" === categorySearch,
            }
          )}
        >
          clearance
        </h1>
        <h1
          onClick={() => {
            setCategorySearch("sale");
          }}
          style={{ borderColor: `#eb6433` }}
          className={classNames(
            "nav-item active electronics is-orange hover-orange",
            {
              "is-orange orange-border border-b-2": "sale" === categorySearch,
            }
          )}
        >
          sale
        </h1>
      </div>
      {children}
    </>
  );
};
