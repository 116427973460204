import _, {uniqBy} from "lodash";
import React, {createRef, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {addCurrent, clearBrand} from "../redux/action-creators";

export const useCatalogCategoryProducts = ({products, selectedCategory}) => {
    const divRef = createRef();
    const dispatch = useDispatch();

    const [containerWidth, setContainerWidth] = React.useState(0);

    const brandName = useSelector((state) => state.current.brandName);
    let productInfo = useSelector((state) => state.products.products);
    const navigationType = useSelector((state) => state.user.navigationType);

    let productsInfo = products.map((s) => productInfo[s.id]).filter(s=>s);

    // TODO cleanup
    useEffect(() => {
        setContainerWidth(divRef?.current?.clientWidth);
    }, [divRef]);

    if (!productInfo || !productsInfo) return null;

    let productBrands;
    if (selectedCategory) {
        productsInfo = productsInfo.filter((product) =>
            _.some(product.categories, {name: selectedCategory})
        );
    }
    productsInfo.map((s, index) => {
        try {
            return s.brand
        } catch (e) {
            console.log(`>> ${index} - ${s}`);
        }
    })
    productBrands = _.sortBy(uniqBy(
        productsInfo.map((s) => s.brand),
        "name"
    ), "name");

    const handleSelectBrand = (name) => (e) => {
        if (name === brandName) {
            return dispatch(clearBrand());
        }
        dispatch(addCurrent({brandName: name}));
        e.stopPropagation();
    };

    return {
        divRef,
        brandName,
        productInfo,
        productBrands,
        navigationType,        
        containerWidth,
        handleSelectBrand,
    }
};
