import React from "react";
import SubCategoriesBar from "./SubCategoriesBar";
import SearchCategoriesFilter from "./SearchCategoriesFilter";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  categoryAtom,
  subMenuTypeAtom,
  toggleSubMenuType,
} from "../project/atoms";
import MyAccountNav from "./MyAccountNav";
import Fade from "./animations/Fade";
import { scrollToTop } from "./project/behaviours";

const SecondNav = (props) => {
  const [submenuType, setSubmenuType] = useRecoilState(subMenuTypeAtom);
  const [category] = useRecoilValue(categoryAtom);

  if (!props.isAuthenticated) {
    return null;
  }

  if (submenuType !== "menu" && !category) {
    // return null;
  }
  return (
    <div
      id="sub-categories"
      className="no-print"
      onClick={() => {
        toggleSubMenuType(submenuType, setSubmenuType);
      }}
    >
      <div
        className="flex justify-around m-auto mt-4 ml p-2 mt-2 rounded flex justify-around"
        style={{ backgroundColor: `RGB(168,168,168)`, maxWidth: 1070 }}
      >
        {submenuType == "menu" ? (
          <MyAccountNav />
        ) : props.searching ? (
          <SearchCategoriesFilter {...props} />
        ) : (
          <SubCategoriesBar {...props} />
        )}
      </div>
    </div>
  );
};

export default SecondNav;
