import { atom } from "recoil";

export const algoliaHits = atom({
  key: "algoliaHits",
  default: [],
});

export const categoryAtom = atom({
  key: "category",
  default: "electronics",
});

export const categorySlugAtom = atom({
  key: "categorySlug",
  default: "electronics",
});

export const subCategoriesAtom = atom({
  key: "subCategories",
  default: [],
});

export const subMenuTypeAtom = atom({
  key: "submenuType",
  default: "menu",
});

export const toggleSubMenuType = (state, setState, cateogry) => {
  setState(state === "subCategories" ? "menu" : "subCategories");
};

export const customersAtom = atom({
  key: "customers",
  default: [],
});

// export const customerAtom = atom({
//   key: "customer",
//   default: { Name: "", custno: "" },
// });

export const ordersAtom = atom({
  key: "orders",
  default: [],
});

export const orderAtom = atom({
  key: "order",
  default: "",
});

export const dataUpdatedAtom = atom({
  key: "dataUpdated",
  default: localStorage.dataUpdated || "",
});

export const settingsNavigationTypeAtom = atom({
  key: "settingsNavigationType",
  default: "menu",
});
