import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import Fade from "./animations/Fade";
import { withRouter } from "react-router";
import {getProductPrice} from "./../project/product"
import { addCurrent } from "../redux/action-creators";


const AS400SearchProduct = ({ history, zreissProduct, handleAddProductToOrder }) => {
  let cartProducts = useSelector((state) => state.cart.products);

  let [isTouched, setIsTouched] = useState(false);
  let [isAdding, setIsAdding] = useState(false);
  let {
    id,
    status,
    qty,
    price,
    brand,
    main_image_hashname,
    itemno,
    model,
    name,
    master_carton,
    clearance_price,
    sale_price
  } = zreissProduct;
  let selected = false; // cursor === index ? true : false;

  let {isClearanceProduct, isSaleProduct} = getProductPrice(zreissProduct)

  let [cartQty, setCartQty] = useState(
    cartProducts[id] ? cartProducts[id].qty : ""
  );

  let [cartNote, setCartNote] = useState(
    cartProducts[id] ? cartProducts[id].note : ""
  );

  useEffect(() => {
    if (isAdding) {
      setTimeout(() => {
        setIsAdding(false);
        setIsTouched(false);
      }, 575);
    }
  }, [isAdding]);

  const addProductToOrder = () => {
    if (parseInt(cartQty)) {
      handleAddProductToOrder(zreissProduct, cartQty, cartNote);
      setIsAdding(true);
    }
  };
  // if(!id) return <pre>{JSON.stringify(zreissProduct, null, 2)}</pre>;
  // return <pre>{JSON.stringify(zreissProduct, null, 2)}</pre>
  return (
    <Fade>
      <div
        id={id}
        onMouseUp={(e) => {
          let selectingText = window.getSelection().toString().length > 1;
          if (!selectingText) {
            history.push(`product/${id}`);
          }
        }}
        className={classNames("flex text-gray-900 rounded search border p-4", {
          "border-2 border-orange-500": selected,
        })}
      >
        <div className="relative text-sm flex flex-col justify-center">
          <img
            alt={name}
            style={{
              width: "150px",
              minWidth: "150px",
              minHeight: "150px",
            }}
            src={
              process.env.REACT_APP_IMG_LIST +
              `/${main_image_hashname || "zreiss-icon-transparent.png"}`
            }
          />
        </div>
        <div
          className="relative flex flex-1 flex-col justify-center ml-4 text-lg"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          {brand.image && (
            <div className="relative mb-2">
              <img
                alt={name}
                style={{
                  width: "66px",
                  maxWidth: "none",
                }}
                src={process.env.REACT_APP_IMG_BRAND + `/${brand.image}`}
              />
              {master_carton > 0 && (
                <div
                  className="absolute text-center text-xs text-gray-600"
                  style={{ left: 75, top: -3 }}
                >
                  Case of {master_carton}
                </div>
              )}
            </div>
          )}
          <div className="text-xs md:text-sm">{name}</div>
          <h3 className="text-gray-700">{model}</h3>
          <h3
            className="text-xs font-semibold pb-2"
            style={{
              bottom: `-21px`,
              width: `150px`,
            }}
          >
            {itemno}
          </h3>
        </div>

        <div
          className="flex justify-between text-center"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          {(isSaleProduct && (
              <div className="flex flex-col justify-center ml-4 mr-4">
                <h1 className="text-gray-800 font-semibold line-through">
                  ${price.toFixed(2)}
                </h1>
                <h1 className="is-orange text-2xl font-semibold">
                  ${sale_price}
                </h1>
              </div>
          )) || (isClearanceProduct && (
            <div className="flex flex-col justify-center ml-4 mr-4">
              <h1 className="text-gray-800 font-semibold line-through">
                ${price.toFixed(2)}
              </h1>
              <h1 className="is-orange text-2xl font-semibold">
                ${clearance_price}
              </h1>
            </div>
          )) || (
              <div className="flex flex-col justify-center ml-4 mr-4">
                <h1 className="is-orange text-2xl font-semibold">
                  ${price.toFixed(2)}
                </h1>
              </div>
            )}
          <div className="flex justify-center">
            <div className="relative flex flex-col justify-center product-row-item flex justify-center align-middle">
              <input
                type="number"
                style={{ width: `54px` }}
                className="text-center"
                value={cartQty}
                onMouseUp={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter" && cartQty) {
                    addProductToOrder();
                  }
                }}
                onChange={(e) => {
                  setCartQty(e.target.value);
                  setIsTouched(true);
                }}
              />
              <h3
                className="absolute text-center text-xs text-gray-600"
                style={{ bottom: `38px`, width: `55px` }}
              >
                {qty > 100 ? "100+" : qty} avail
              </h3>
            </div>
          </div>
          <div className="flex flex-col pr-4 justify-center product-row-item flex-col justify-center">
            <button
              className={classNames({
                "text-lg": cartQty && !isTouched,
                "is-loading": isAdding,
              })}
              onMouseUp={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
              onClick={(e) => {
                addProductToOrder();
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              {(cartQty && !isTouched && <span className="text-2xl">✓</span>) ||
                "ADD"}
            </button>
          </div>
        </div>
      </div>
    </Fade>
  );
};

export default withRouter(AS400SearchProduct);