import React from "react";
import { useDispatch } from "react-redux";
import submitFeedback from "../redux/actions/submitFeedback";

export default () => {
  let [showForm, setShowForm] = React.useState(false);
  const [feedback, setFeedback] = React.useState("");
  const dispatch = useDispatch();
  const handleSubmit = () => {
    const orderFeedback = localStorage.cart
    dispatch(submitFeedback(orderFeedback));
    setFeedback("");
    setShowForm(false);
  };
  return (
    <div id="footer" className="text-center mt-8 mb-16 relative">
      {showForm && (
        <>
          <textarea
            id={"feedback"}
            autoFocus
            className="border-black h-32"
            placeholder={"Tell use if you see..."}
            onChange={e => setFeedback(e.target.value)}
            value={feedback}
          />
          {feedback.length > 3 && (
            <button
              className="absolute bottom-0 right-0"
              onClick={() => {
                handleSubmit();
              }}
            >
              Submit
            </button>
          )}
        </>
      )}
      <button
        className="mt-8"
        onClick={() => {
          setShowForm(!showForm);
          setFeedback("");
          setTimeout(() => {
            window.scrollTo(0, document.body.scrollHeight);
          }, 100);
        }}
      >
        Give Us Feedback
      </button>
    </div>
  );
};
