import axios from "axios"
import { getUserToken } from "./functions";

export const fetchOrder = async (id) => {
  try {
    const auth = { headers: { Authorization: `Bearer ${getUserToken()}` } };
    const data = await axios.get(
      `${process.env.REACT_APP_API}/order/${id}`,
      auth
    );
    return data.data;
  } catch (error) {
    alert(error); // catches both errors
  }
};
