import axios from "axios";

export default function searchAll(search, setProducts, setIsZreissSearching) {
    return (dispatch, getState) => {
        let token = getState().user.token;
        const auth = {headers: {Authorization: `Bearer ${token}`}};

        axios
            .post(`${process.env.REACT_APP_API}/products/like`, {search}, auth)
            .then(({data}) => {
                dispatch({type: "FETCH_ZREISS_PRODUCTS_RECEIVED", payload: data});
                setProducts(data);
                setIsZreissSearching(false);
            });
    };
}
