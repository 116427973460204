import Product from "./Product";
import Fade from "./animations/Fade";
import MiniProduct from "./MiniProduct";
import {useCatalogCategoryProducts} from "./CatalogCategoryProducts.hook";
import {CatalogCategoryProductBrands} from "./CatalogCategoryProductBrands";

export const CatalogCategoryProducts = ({products, selectedCategory}) => {
    let {
        divRef,
        brandName,
        productInfo,
        productBrands,
        navigationType,        
        containerWidth,
        handleSelectBrand,
    }   = useCatalogCategoryProducts({products, selectedCategory}); 

    return (
        <>
            <Fade>
                <div>
                    <div ref={divRef}
                         style={{minHeight: 100}}
                         className="flex flex-wrap border-t mt-2 items-center"
                    >
                        <CatalogCategoryProductBrands
                            brandName={brandName}
                            productBrands={productBrands}
                            containerWidth={containerWidth}
                            handleSelectBrand={handleSelectBrand}
                        />
                    </div>
                </div>
                <hr/>
                <div>
                    {navigationType === "catalog" ? (
                        <div className="product-grid">
                            {products.map((s, index) => (
                                <Product
                                    index={index}
                                    cursor={true}
                                    key={"p" + s.id}
                                    brandName={brandName}
                                    product={productInfo[s.id]}
                                    selectedCategory={selectedCategory}
                                />
                            ))}
                        </div>
                    ) : (
                        <div className="product-mini-grid">
                            {products.map((s, index) => (
                                <MiniProduct
                                    index={index}
                                    cursor={true}
                                    key={"p" + s.id}
                                    brandName={brandName}
                                    product={productInfo[s.id]}
                                    selectedCategory={selectedCategory}
                                />
                            ))}
                        </div>
                    )}
                </div>
            </Fade>
        </>
    );
};
