import clearCart from "./clearCart";
import {addCartCustomer} from "../action-creators/addCartCustomer";

export function submitOrder(order, customer, status = 1, history, setOrder, setSubmitted, setErrorMessage) {
    return (dispatch, getState) => {

        const {user} = getState();
        let sl = user.sl;

        order.custno = (customer && customer.Custno) || "";
        order.status = status;

        let token = getState().user.token;

        dispatch({type: "SUBMIT_ORDER_START"});

        fetch(`${process.env.REACT_APP_API}/order/${sl}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({...order, status}),
        })
            .then((resp) => resp.json())
            .then((data) => {
                dispatch({type: "SUBMIT_ORDER_STOP"});
                dispatch({type: "SUBMIT_ORDER_SUCCEEDED"});
                dispatch({type: "RESET_ORDER"});

                if (data) {
                    if (data.error) {
                        console.log("error", data.error)
                        setSubmitted(false);
                        setErrorMessage("there was an error please try again")

                        return false;
                    }

                    dispatch(clearCart());
                    dispatch(addCartCustomer(""));
                    history.push(`/order/${data.id}`);
                }
            })
            .catch((error) => {
                console.log(error);
                setSubmitted(false);
                setErrorMessage("There was a Server Error");

                dispatch({type: "CART_SUBMIT_ORDER_STOP"});
                dispatch({type: "CART_SUBMIT_ORDER_FAILED"});
            });
    };
}
