import React from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import fetchProductsAndCategories from "../redux/actions/fetchProductsAndCategories";
import { DateTime } from "luxon";
import jwtDecode from "jwt-decode";
import Loader from "react-loader-spinner";
import { submitOrderData } from "../redux/actions/submitOrderData";
import { addUser } from "../redux/action-creators/user";
import { changeNavigation, getNavigationType } from "./project/toggles";
import Feedback from "./Feedback";

const Settings = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const navigationType = useSelector((state) => state.user.navigationType);
  const dataUpdated = useSelector((state) => state.products.serverUpdated);
  const isLoading = useSelector((state) => state.products.fetching);
  const expire = (user.token && jwtDecode(user.token).exp) || null;
  const tokenExpires =
    expire &&
    DateTime.fromSeconds(expire).toLocaleString(
      DateTime.DATETIME_SHORT_WITH_SECONDS || "none"
    );

  const handleSubmitOrderData = () => {
    dispatch(submitOrderData());
  };
  return (
    <>
      <h1 className="p-8 text-xl text-gray-900">Settings</h1>
      <ul className="pl-8 pr-8 text-lg text-gray-700">
        <li className="flex justify-between">
          <div>
            <button onClick={() => dispatch(fetchProductsAndCategories())}>
              {isLoading ? (
                <Loader type="ThreeDots" color="#fff" height={10} />
              ) : (
                "Update Data"
              )}
            </button>
            <h1>{dataUpdated && `Last Updated: ${dataUpdated}`}</h1>
          </div>
          <div className="text-right">
            <button className="bg-black pl-6 pr-6">
              <Link to={"logout"} className="">
                Logout
              </Link>
            </button>
            <h1>Token Expires: {tokenExpires}</h1>
          </div>
        </li>
        <li className="mt-8 flex justify-between">
          <div className="text-right">
            <h1>Navigation</h1>
            <button
              className="bg-black pl-6 pr-6"
              onClick={changeNavigation(navigationType, dispatch)}
            >
              {navigationType}
            </button>
          </div>
          <div className="text-right">
            <h1>Clear Cart Cache</h1>
            <button
              className="pl-6 pr-6"
              onClick={() => {
                delete localStorage.cart;
                window.location.reload();
              }}
            >
              Clear
            </button>
          </div>
        </li>
        <li className="mt-8 flex justify-between">
          <div className="">
            <h1>Send Order Data</h1>
            <button className="pl-6 pr-6" onClick={handleSubmitOrderData}>
              Send Cart
            </button>
          </div>
          <div className="text-right">
            <h1>Clear Storage</h1>
            <button
                className="pl-6 pr-6"
                onClick={()=>localStorage.clear()}
            >
              Clear
            </button>
          </div>
        </li>
      </ul>
      <div className="flex justify-center"></div>
      <Feedback/>
    </>
  );
};

export default Settings;
