import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import classNames from "classnames";
import Fade from "./animations/Fade";
import {formatMoney} from "../helpers";
import {addToOrder} from "../redux/actions/addToOrder";

export const CartProduct = ({id, info, price: cartPrice, qty, note, priceType, saleMessage, handleRemoveItem}) => {
    const dispatch = useDispatch();
    let [cartQty, setCartQty] = useState(qty);
    let [cartNote, setCartNote] = useState(note);
    let [showNote, setShowNote] = useState(false);
    let [isChanged, setIsChanged] = useState(false);
    let product = useSelector(
        (state) => state.products.products[id] || info || false
    );
    // product =  product ? product : zreiss
    let selected = false; // cursor === index ? true : false;
    let touched = React.useRef(false);

    useEffect(() => {
        if (touched.current) {
            return setIsChanged(true);
        }
        touched.current = cartQty;
    }, [cartQty]);

    if (!product) {
        return null;
    }

    const handleAdd = () => {
        setIsChanged(false);
        setShowNote(false);
        touched.current = cartQty;

        dispatch(addToOrder(product, cartQty, cartNote));
    };
    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            handleAdd();
        }
    };

    let {price, brand, name, main_image_hashname, itemno} = product;
    let avail = product.qty > 100 ? "100+" : product.qty;

    return (
        <Fade>
            <div
                className={classNames(
                    "flex text-gray-900 rounded search border border-t-0 p-4",
                    {
                        "border-2 border-orange-500": selected,
                    }
                )}
            >
                <div className="relative text-sm flex flex-col justify-center">
                    <img
                        alt={name}
                        style={{
                            width: 150,
                            maxWidth: "none",
                        }}
                        src={process.env.REACT_APP_IMG_LIST + `/${main_image_hashname}`}
                    />
                </div>
                <div
                    className="flex flex-1 flex-col justify-center ml-4 text-lg"
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                    }}
                >
                    <div className="font-bold text-black uppercase">
                        {brand && brand.name}
                    </div>
                    <div className="text-sm">{name}</div>
                    <h3
                        className="text-xs font-semibold"
                        style={{
                            bottom: `-21px`,
                            width: `150px`,
                        }}
                    >
                        {itemno}
                    </h3>
                    {saleMessage && (
                        <p className={"text-sm text-red-600"}>* to get sale price{saleMessage}</p>
                    )}
                    <div className="flex items-center">
                        <h1
                            className="is-orange text-2xl font-semibold"
                            onClick={() => setShowNote(!showNote)}
                        >
                            {formatMoney(cartPrice)}
                        </h1>
                        {priceType !== 'price' && (
                            <span className="ml-3 pl-1 pr-1 text-xs bg-green-600 text-white rounded"
                                  style={{height: 18}}>
                            {priceType}
                            </span>
                        )}
                        <span className="ml-5 text-sm text-gray-700">{note}</span>
                    </div>
                    {showNote && (
                        <input
                            autoFocus
                            value={cartNote}
                            placeholder="Item Note"
                            onKeyDown={handleKeyDown}
                            onChange={(event) => {
                                setCartNote(event.target.value);
                                setIsChanged(true);
                            }}
                        />
                    )}
                </div>

                <div
                    className="flex justify-between text-center"
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                    }}
                >
                    <div className="flex justify-center">
                        <div
                            className="relative flex flex-col justify-center product-row-item flex justify-center align-middle">
                            <h3
                                className="absolute text-center text-xs text-gray-600"
                                style={{bottom: `96px`, width: `54px`}}
                            >
                                <span className="text-xs">qty</span>
                            </h3>

                            <input
                                style={{width: `54px`}}
                                type="number"
                                className="text-center"
                                onKeyDown={handleKeyDown}
                                value={cartQty}
                                onChange={(event) => setCartQty(event.target.value)}
                            />
                            <h3
                                className="absolute text-center text-xs text-gray-600"
                                style={{bottom: `38px`, width: `57px`}}
                            >
                                {avail} <span className="text-xs">avail</span>
                            </h3>
                        </div>
                    </div>
                    <div className="relative flex flex-col justify-center product-row-item">
                        <div className="relative">
                            <h1 className="is-orange text-2xl font-semibold">
                                {formatMoney(cartPrice * cartQty)}
                            </h1>
                            {price !== cartPrice && (
                                <>
                                    <h6 className="absolute font-semibold line-through" style={{
                                        top: -5,
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)'
                                    }}>
                                        {formatMoney(price * cartQty)}
                                    </h6>
                                </>
                            )}
                        </div>
                    </div>
                    {!isChanged && (
                        <div className="relative flex flex-col justify-center product-row-item">
                            <div
                                type="button"
                                className="close ml-1"
                                aria-label="Close"
                                onClick={() => handleRemoveItem(id)}
                            >
                <span
                    aria-hidden="true"
                    className="text-gray-500 text-3xl cursor-pointer hover:text-gray-900"
                >
                  &times;
                </span>
                            </div>
                        </div>
                    )}
                    {isChanged && (
                        <div className="relative flex flex-col justify-center product-row-item">
                            <hr/>
                            <button onClick={handleAdd}>ADD</button>
                            <h3
                                className="absolute text-center text-xs text-gray-600"
                                style={{bottom: `38px`, width: `54px`}}
                                onClick={() => {
                                    setCartQty(touched.current);
                                    setIsChanged(false);
                                    touched.current = false;
                                }}
                            >
                                cancel
                            </h3>
                        </div>
                    )}
                </div>
            </div>
        </Fade>
    );
};
