import { combineReducers } from "redux";
import { products } from "./products";
import { user } from "./user";
import { cart } from "./cart";
import current from "./current";
import show from "./show";

export default combineReducers({
  user,
  cart,
  show,
  current,
  products,
});

/*
let {products, productSearch, orders, customers, order, user, page} = store.getState()
*/
