import React from "react";
import { useSpring, animated } from "react-spring";

export default function Grow(props) {
  const spring = useSpring({
    config: { duration: 130 },
    from: { transform: "scale(0.4)" },
    delay: 0,
    to: { transform: "scale(1)" },
    leave: { opacity: 0 },
  });

  return (
    <animated.div key={props.key} style={spring}>
      {props.children}
    </animated.div>
  );
}
