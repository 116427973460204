import store from "./../store";

export function addShow(object) {
  return {
    type: "SHOW",
    payload: object,
  };
}

export function showToggle(field) {
  const toggle = !store.getState().show[field];
  return {
    type: "SHOW",
    payload: { [field]: toggle },
  };
}

export function clearObjects() {
  return {
    type: "CURRENT_OBJECTS_CLEAR",
  };
}
