import React from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { scrollToTop } from "./project/behaviours";
import { clearBrand } from "../redux/action-creators";

const SubCategory = ({ category, searchCategory }) => {
  const currentCategory = useSelector((state) => state.current.category);
  let { name, slug } = category;
  const dispatch = useDispatch();
  return (
    <div className="flex items-center">
      <span className="text-xs text-gray-900 mr-1">&bull;</span>
      <Link
        style={{ textTransform: `none` }}
        onClick={(e) => {
          searchCategory(e, category);
          dispatch(clearBrand());
          scrollToTop();
        }}
        to={`/${slug}`}
        key={slug}
        className={classNames("text-gray-900 hover-orange", {
          "is-orange": name == currentCategory.name,
        })}
      >
        {name}
      </Link>
    </div>
  );
};

export default SubCategory;
