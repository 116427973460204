import axios from "axios";
import classNames from "classnames";
import {useParams} from "react-router";
import ProductImage from "./ProductImage";
import {addCurrent} from "../redux/action-creators";
import {addToOrder} from "../redux/actions/addToOrder";
import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {cart} from "../redux/reducers/cart";
import {noImageBrands} from "../project/brands";

const filteredQty = (qty) =>
    qty > 100 ? "100+ Available" : qty < 1 ? "Out Of Stock" : `${qty} Available`;

function createMarkup(html) {
    return {__html: html};
}

export default function ProductDetail() {
    const dispatch = useDispatch();

    let {id} = useParams();
    let cartProducts = useSelector((state) => state.cart.products);
    let token = useSelector((state) => state.user.token);

    const [product, setProduct] = useState("");
    const [selectedImage, setSelectedImage] = useState(0);
    const [description, setDescription] = useState("");
    let [cartQty, setCartQty] = useState(
        cartProducts[id] ? cartProducts[id].qty : ""
    );
    let [isTouched, setIsTouched] = useState(false);
    let [isAdding, setIsAdding] = useState(false);

    useEffect(() => {
        window.scroll({top: 0, left: 0});
        const auth = {headers: {Authorization: `Bearer ${token}`}};
        axios
            .get(`${process.env.REACT_APP_API}/product/${id}`, auth)
            .then(({data}) => {
                data.isClearanceProduct = data.status === "C" && data.clearance_price;
                data.isSalePrice = data.sale_price > 0;
                if (data.images.length < 1) {
                    let defaultImage = {
                        filename: "zreiss+icon.png",
                        label: "",
                    };
                    data.images.push(defaultImage);
                }
                setProduct(data);
                setDescription(data.description[0].description);
                
                const product = document.getElementById("productDescription");
                product.style.minHeight = product.offsetHeight + "px";
                dispatch(addCurrent({comingFromId: id}));
            });
    }, []);

    useEffect(() => {
        if (isAdding) {
            setTimeout(() => {
                setIsAdding(false);
                setIsTouched(false);
            }, 575);
        }
    }, [isAdding]);

    const addProductToOrder = (product, qty, note) => {
        dispatch(addToOrder(product, cartQty, note));
    };

    if (!product) {
        return null;
    }

    let {sale_price, sale_min_quantity, sale_min_order_total} = product;

    let saleMessage = "";
    if(sale_price > 0){
        saleMessage = "* to qualify for sale price, ";
        let minQtyMessage = sale_min_quantity > 0 ? "order at least " + sale_min_quantity : "";
        let orderTotalMessage = sale_min_order_total > 0 ? "order must total at least $" + sale_min_order_total : "";
        
        if(minQtyMessage != "" && orderTotalMessage != ""){
            saleMessage += minQtyMessage + " and " + orderTotalMessage;
        } else {
            saleMessage += minQtyMessage + orderTotalMessage;
        }
    }

    return (
        <div className="productDetail items-center">
            <div className="productDetail-image">
                <img
                    alt={product.images[selectedImage].label}
                    src={
                        process.env.REACT_APP_IMG_PRODUCT +
                        "/" +
                        product.images[selectedImage].filename
                    }
                />
            </div>
            <div className="productDetail-info flex flex-col">
                <div>
                    <h1
                        className="text-3xl text-gray-900 font-extrabold mt-10 pr-4"
                        style={{lineHeight: `1.2`, maxWidth: `450px`}}
                    >
                        {product.name}
                    </h1>
                </div>
                <div className="flex items-center mt-2 mb-2 text-gray-900">
                    {!noImageBrands.includes(product.brand.name) ? (product.brand.image && (
                        <img
                            alt={product.brand.name}
                            src={process.env.REACT_APP_IMG_BRAND + "/" + product.brand.image}
                            style={{height: "15px"}}
                        />)) : <h1>{product.brand.name}</h1>}
                    <span className="ml-2 text-xl">{product.model}</span>
                    <span className="ml-2 mr-2">|</span>
                    <span className="text-gray-800 font-thin">{product.upc}</span>
                </div>
                <hr/>
                <div className="flex flex-col">
                    <h1 className="mt-1 text-gray-800 text-2xl">{product.itemno}</h1>
                    <div className="mt-1 text-sm text-gray-900">
                        <div className=""> {filteredQty(product.qty)}</div>
                        {product.master_carton > 0 && (
                            <div className="">
                                {" "}
                                {"Case Count: "} {product.master_carton}
                            </div>
                        )}
                        {product.inner_carton > 0 && (
                            <div className="">
                                {" "}
                                {"Inner Qty: "} {product.inner_carton}
                            </div>
                        )}
                    </div>
                    <hr className="mt-2 mb-2"/>
                    <div className="flex flex-col text-black">
                        {saleMessage}
                    </div>
                    <div
                        className="flex justify-between is-orange text-2xl font-semibold"
                        style={{maxWidth: `320px`}}
                    >
                        {(product.isSalePrice && (
                            <div className="flex flex-col justify-center ml-4 mr-4 text-center">
                                <h1 className="text-gray-800 text-xl font-semibold line-through">
                                    ${product.price.toFixed(2)}
                                </h1>
                                <h1 className="is-orange text-3xl font-semibold">
                                    ${product.sale_price.toFixed(2)}
                                </h1>
                            </div>
                        )) || (product.isClearanceProduct && (
                            <div className="flex flex-col justify-center ml-4 mr-4 text-center">
                                <h1 className="text-gray-800 text-xl font-semibold line-through">
                                    ${product.price.toFixed(2)}
                                </h1>
                                <h1 className="is-orange text-3xl font-semibold">
                                    ${product.clearance_price.toFixed(2)}
                                </h1>
                            </div>
                        )) || (
                            <span className="pl-2 text-3xl">${product.price.toFixed(2)}</span>
                        )}
                        <div className="mt-1 flex flex-col items-end">
                            <input
                                value={cartQty}
                                className={classNames("text-center", {
                                    "text-lg": cartQty && !isTouched,
                                    "is-loading": isAdding,
                                })}
                                style={{width: `54px`}}
                                placeholder="Qty"
                                onChange={(e) => {
                                    setCartQty(e.target.value);
                                    setIsTouched(true);
                                }}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter" && cartQty) {
                                        addProductToOrder(product, cartQty, "");
                                        setIsAdding(true);
                                    }
                                }}
                            />
                            <button
                                className={classNames("product-detail-add mt-2", {
                                    "text-lg": cartQty && !isTouched,
                                    "is-loading": isAdding,
                                })}
                                onClick={() => {
                                    addProductToOrder(product, cartQty, "");
                                    setIsAdding(true);
                                }}
                            >
                                {(cartQty && !isTouched && "✓") || "ADD TO CART"}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="productDetail-thumbnails flex flex-wrap ml-6">
                {product.images.map((s, index) => (
                    <ProductImage
                        selected={selectedImage === index}
                        image={s}
                        handleSelected={() => setSelectedImage(index)}
                    />
                ))}
            </div>
            <div className="productDetail-descriptions ml-6 mt-4 mr-6">
                <div className="flex is-orange uppercase font-bold text-lg">
          <span
              className="cursor-pointer hover:text-orange-900"
              onClick={() => setDescription(product.description[0].description)}
          >
            Description
          </span>
                    {product.specifications.length > 0 &&
                        product.specifications[0].description && (
                            <>
                                <span className="separator text-black">|</span>
                                <span
                                    className="cursor-pointer hover:text-orange-900"
                                    onClick={() =>
                                        setDescription(product.specifications[0].description)
                                    }
                                >
                  Specifications
                </span>
                            </>
                        )}
                    {product.includes.length > 0 && product.includes[0].description && (
                        <>
                            <span className="separator text-black">|</span>
                            <span
                                className="cursor-pointer hover:text-orange-900"
                                onClick={() => setDescription(product.includes[0].description)}
                            >
                Includes
              </span>
                        </>
                    )}
                </div>
                <p
                    id="productDescription"
                    className="mt-2 text-gray-900 mb-6"
                    dangerouslySetInnerHTML={createMarkup(description)}
                />
            </div>
        </div>
    );
}
